import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';

export default function PieChart() {
    const [chartData, setChartData] = useState({
        series: [],
        options: {
            chart: {
                width: 480,
                type: 'pie',
            },
            labels: [],
            colors: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    const { data, isLoading, isError } = useQuery('AllCount', getAllCount, {
        cacheTime: 9000000,
        staleTime: 600000,
    });


    useEffect(() => {
        if (!data) return;

        if (data?.success) {

            const counts = [
                data.data.users,
                data.data.coupon,
                data.data.categories,
                data.data.brands,
                data.data.ads,
                data.data.locations,
                data.data.notifications
            ];

            const labels = [
                "Total Users",
                "Total Coupons",
                "Total Categories",
                "Total Brands",
                "Total Ads",
                "Total Locations",
                "Total Notifications"
            ];
            const colors = [
                "#1E90FF",
                "#32CD32",
                "#FF6347",
                "#FFD700",
                "#6A5ACD",
                "#FF4500",
                "#20B2AA"
            ];

            setChartData({
                series: counts,
                options: {
                    chart: {
                        width: 480,
                        type: 'pie',
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                    },
                    dropShadow: {
                        enabled: true,
                        top: 10,
                        left: 0,
                        blur: 5,
                        opacity: 0.8
                    },
                    tooltip: {
                        enabled: true,
                        y: {
                            formatter: (val) => `${val} counts`
                        },
                        style: {
                            fontSize: '14px',
                            fontFamily: 'Arial, sans-serif',
                        }
                    },
                    fill: {
                        type: 'solid',
                        // gradient: {
                        //     shade: 'light',        // Options: 'light', 'dark'
                        //     type: 'vertical',      // Options: 'horizontal', 'vertical', 'diagonal1', 'diagonal2', 'radial'
                        //     shadeIntensity: 0.5,   // Intensity of the shade
                        //     gradientToColors: ["#4CAF50"], // Array of colors for gradient
                        //     inverseColors: true,
                        //     opacityFrom: 1,
                        //     opacityTo: 0.9,
                        //     stops: [0, 100]        // Start and end points for gradient
                        // }
                    },
                    legend: {
                        show: true,
                        position: 'right',
                        fontSize: '16px',
                        fontWeight: 600,
                        labels: {
                            colors: "#333",
                        },
                        markers: {
                            radius: 5
                        }
                    },
                    labels: labels,
                    colors: colors,
                    responsive: [{
                        breakpoint: 900,
                        options: {
                            chart: {
                                width: 400
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            });
        }
    }, [data]);

    async function getAllCount() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/dashboard/counts`);
            return response?.data;
        } catch (error) {
            // console.error('Error fetching data:', error);
            throw new Error('Failed to fetch data');
        }
    }

    if (isLoading) return <div className="loading">
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#199A8E"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
        /></div>

    if (isError) return <div>Error fetching data</div>;

    return (
        <div className="container py-1.5 h-full w-full">
            <h1 className='text-center text-teal-500 font-extrabold text-xl xl:text-3xl'>All Data Count in Pie Chart</h1>
            <div id="chart" className='cursor-pointer mt-5 py-10 flex justify-center align-middle'>
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    width={550}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}
