import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export let UserContext = createContext()

export default function UserContextProvider(props) {

    const [refresh_token, setRefresh_token] = useState(null)
    const [userData, setUserData] = useState(null)


    async function getLoggedUser() {

        try {

            if (localStorage.getItem('refresh_token') !== null) {



            } else {

                return <Navigate to={'/login'} />
            }

        } catch (error) {


        }

    }


    useEffect(() => {
        getLoggedUser()
    }, [])


    return <UserContext.Provider value={{ refresh_token, setRefresh_token, setUserData, userData }}>
        {props.children}
    </UserContext.Provider>
}
