import axios from 'axios';
import React, { useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

export default function Country() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)
    const [totalPages, setTotalPages] = useState(5); // Total available pages
    const [currentPage, setCurrentPage] = useState(1); // Current page

    let navigate = useNavigate();

    const [sortName, setSortName] = useState(false);
    const ToggleSortName = () => {
        setSortName(!sortName);
        refetch();
    };

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };


    async function getAllCountries() {

        const searchParams = searchQuery ? `&search=${searchQuery}&searchField=name` : "";
        const sortParams = sortName ? "&sortBy=name&sortOrder=DESC" : "";

        try {
            let response = await axios.get(
                `${process.env.REACT_APP_BACK_END}admin/countries?page=${currentPage}${searchParams}${sortParams}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                        'Accept-Language': '',
                    }
                }
            );

            setTotalPages(response?.data?.totalPages);

            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('refresh_token');
                navigate('/login');
            } else {
                throw error;
            }
        }
    }

    let { isLoading, data, refetch } = useQuery(
        ['AllCountries', currentPage, searchQuery, sortName],
        () => getAllCountries(),
        {
            cacheTime: 9000000,            // Cache the data for a long time (milliseconds)
            staleTime: 9000000,            // Consider the data fresh for a long time
            refetchOnWindowFocus: false,   // Don't refetch when the window gains focus
            refetchOnMount: false,         // Don't refetch when the component mounts
            refetchOnReconnect: false,     // Don't refetch on reconnect
            refetchInterval: false         // Disable polling
        }
    );

    let allCountries = []

    if (data?.data?.success) {
        allCountries = data?.data?.data
    }



    // modal
    const toggleModal = () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');


    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add country
    const [image, setImage] = useState(null); // Use null instead of empty string for files
    const [ogImage, setOgImage] = useState(null); // Use null instead of empty string for files
    const [twitterImage, setTwitterImage] = useState(null); // Use null instead of empty string for files
    function handelImage(e) {
        setImage(e.target.files[0])
    }

    function handelOgImage(e) {
        setOgImage(e.target.files[0])
    }

    function handleTwitterImage(e) {
        setTwitterImage(e.target.files[0])
    }

    // Function to submit add country
    async function submitAddCountry(values) {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('countryCode', values.countryCode);
        formData.append('image', image);

        // Localized SEO and Social Media fields
        formData.append('seoDescription[en]', values.seoDescriptionEn);
        formData.append('seoDescription[ar]', values.seoDescriptionAr);
        formData.append('seoKeywords[en]', values.seoKeywordsEn);
        formData.append('seoKeywords[ar]', values.seoKeywordsAr);

        // OG Fields
        formData.append('ogTitle[en]', values.ogTitleEn);
        formData.append('ogTitle[ar]', values.ogTitleAr);
        formData.append('ogDescription[en]', values.ogDescriptionEn);
        formData.append('ogDescription[ar]', values.ogDescriptionAr);
        formData.append('ogImage', ogImage);
        formData.append('ogUrl', values.ogUrl);

        // Twitter Fields
        formData.append('twitterCard', values.twitterCard);
        formData.append('twitterTitle[en]', values.twitterTitleEn);
        formData.append('twitterTitle[ar]', values.twitterTitleAr);
        formData.append('twitterDescription[en]', values.twitterDescriptionEn);
        formData.append('twitterDescription[ar]', values.twitterDescriptionAr);
        formData.append('twitterImage', twitterImage);


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}admin/countries`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                }
            }
            );

            await refetch();
            if (response.data.success) {
                formik.resetForm();
                toggleModal();
                toast.success('country added successfully!');
            }
        } catch (error) {
            setError(error?.response?.data?.errors?.map(elm => elm.message));
        }
    }


    let validateSchemaAdd = Yup.object({
        name: Yup.string().required('country name is required'),
        countryCode: Yup.string().required('Country code is required'),
        image: Yup.mixed().required('Image is required'),

        // SEO Descriptions
        seoDescriptionEn: Yup.string().required('SEO description (EN) is required'),
        seoDescriptionAr: Yup.string().required('SEO description (AR) is required'),

        // SEO Keywords
        seoKeywordsEn: Yup.string().required('SEO keywords (EN) are required'),
        seoKeywordsAr: Yup.string().required('SEO keywords (AR) are required'),

        // OG Title and Descriptions
        ogTitleEn: Yup.string().required('OG title (EN) is required'),
        ogTitleAr: Yup.string().required('OG title (AR) is required'),
        ogDescriptionEn: Yup.string().required('OG description (EN) is required'),
        ogDescriptionAr: Yup.string().required('OG description (AR) is required'),

        // OG Image and URL
        ogImage: Yup.mixed().required('ogImage is required'),
        // ogImage: Yup.mixed().required('OG image is required').nullable(),
        ogUrl: Yup.string().url('Must be a valid URL').required('OG URL is required'),

        // Twitter Metadata
        twitterCard: Yup.string().required('Twitter card type is required'),
        twitterTitleEn: Yup.string().required('Twitter title (EN) is required'),
        twitterTitleAr: Yup.string().required('Twitter title (AR) is required'),
        twitterDescriptionEn: Yup.string().required('Twitter description (EN) is required'),
        twitterDescriptionAr: Yup.string().required('Twitter description (AR) is required'),
        // twitterImage: Yup.mixed().required('Twitter image is required').nullable(),
        twitterImage: Yup.mixed().required('twitterImage is required'),
    })

    let formik = useFormik({
        initialValues: {
            name: '',
            countryCode: '',
            image: null,
            seoDescriptionEn: '',
            seoDescriptionAr: '',
            seoKeywordsEn: '',
            seoKeywordsAr: '',
            ogTitleEn: '',
            ogTitleAr: '',
            ogDescriptionEn: '',
            ogDescriptionAr: '',
            ogImage: null,
            ogUrl: '',
            twitterCard: '',
            twitterTitleEn: '',
            twitterTitleAr: '',
            twitterDescriptionEn: '',
            twitterDescriptionAr: '',
            twitterImage: null,

        }, validationSchema: validateSchemaAdd,
        onSubmit: submitAddCountry

    })


    // update country
    const [updateCountryData, setUpdateCountryData] = useState(null);
    const [updatingCountryId, setUpdatingCountryId] = useState(null);

    const [imageUpdate, setImageUpdate] = useState(null); // Use null instead of empty string for files
    const [ogImageUpdate, setOgImageUpdate] = useState(null); // Use null instead of empty string for files
    const [twitterImageUpdate, setTwitterImageUpdate] = useState(null); // Use null instead of empty string for files

    const [imageUpdatePrivous, setImageUpdatePrivous] = useState(null);
    const [ogImageUpdatePrivous, setOgImageUpdatePrivous] = useState(null);
    const [twitterImageUpdatePrivous, setTwitterImageUpdatePrivous] = useState(null);


    const handleImageUpdate = (e) => {
        const file = e.target.files[0];
        setImageUpdate(file);
    };

    const handleOgImageUpdate = (e) => {
        const file = e.target.files[0];
        setOgImageUpdate(file);
    };

    const handleTwitterImageUpdate = (e) => {
        const file = e.target.files[0];
        setTwitterImageUpdate(file);
    };


    // Function to handle update button click
    const handleUpdateClick = (country) => {

        setUpdateCountryData(country);
        setUpdatingCountryId(country.id);

        setImageUpdatePrivous(country.image); // Assuming country.imageUrl holds the URL of the existing image
        setOgImageUpdatePrivous(country.ogImage); // Assuming ogImageUrl is available
        setTwitterImageUpdatePrivous(country.twitterImage);

        // Set Formik initial values based on the country data
        formikUpdate.setValues({
            name: country.name || '',
            countryCode: country.countryCode || '',
            image: null,
            seoDescriptionEn: country.seoDescription.en || '',
            seoDescriptionAr: country.seoDescription.ar || '',
            seoKeywordsEn: country.seoKeywords.en || '',
            seoKeywordsAr: country.seoKeywords.ar || '',
            ogTitleEn: country.ogTitle.en || '',
            ogTitleAr: country.ogTitle.ar || '',
            ogDescriptionEn: country.ogDescription.en || '',
            ogDescriptionAr: country.ogDescription.ar || '',
            ogImage: null,
            ogUrl: country.ogUrl || '',
            twitterCard: country.twitterCard || '',
            twitterTitleEn: country.twitterTitle.en || '',
            twitterTitleAr: country.twitterTitle.ar || '',
            twitterDescriptionEn: country.twitterDescription.en || '',
            twitterDescriptionAr: country.twitterDescription.ar || '',
            twitterImage: null,
        });

        toggleModalUpdate();

    };


      async function submitUpdateCountry(values) {
        try {
            // Create a flag to track if any fields have been updated
            let hasUpdates = false;

            const formData = new FormData();

            // Compare each field with the original value, and only append if changed
            if (values.name !== updateCountryData.name) {
                formData.append('name', values.name);
                hasUpdates = true;
            }

            if (values.countryCode !== updateCountryData.countryCode) {
                formData.append('countryCode', values.countryCode);
                hasUpdates = true;
            }

            if (values.seoDescriptionEn !== updateCountryData.seoDescription.en) {
                formData.append('seoDescription[en]', values.seoDescriptionEn);
                hasUpdates = true;
            }

            if (values.seoDescriptionAr !== updateCountryData.seoDescription.ar) {
                formData.append('seoDescription[ar]', values.seoDescriptionAr);
                hasUpdates = true;
            }

            if (values.seoKeywordsEn !== updateCountryData.seoKeywords.en) {
                formData.append('seoKeywords[en]', values.seoKeywordsEn);
                hasUpdates = true;
            }

            if (values.seoKeywordsAr !== updateCountryData.seoKeywords.ar) {
                formData.append('seoKeywords[ar]', values.seoKeywordsAr);
                hasUpdates = true;
            }

            if (values.ogTitleEn !== updateCountryData.ogTitle.en) {
                formData.append('ogTitle[en]', values.ogTitleEn);
                hasUpdates = true;
            }

            if (values.ogTitleAr !== updateCountryData.ogTitle.ar) {
                formData.append('ogTitle[ar]', values.ogTitleAr);
                hasUpdates = true;
            }

            if (values.ogDescriptionEn !== updateCountryData.ogDescription.en) {
                formData.append('ogDescription[en]', values.ogDescriptionEn);
                hasUpdates = true;
            }

            if (values.ogDescriptionAr !== updateCountryData.ogDescription.ar) {
                formData.append('ogDescription[ar]', values.ogDescriptionAr);
                hasUpdates = true;
            }

            if (values.ogUrl !== updateCountryData.ogUrl) {
                formData.append('ogUrl', values.ogUrl);
                hasUpdates = true;
            }

            if (values.twitterCard !== updateCountryData.twitterCard) {
                formData.append('twitterCard', values.twitterCard);
                hasUpdates = true;
            }

            if (values.twitterTitleEn !== updateCountryData.twitterTitle.en) {
                formData.append('twitterTitle[en]', values.twitterTitleEn);
                hasUpdates = true;
            }

            if (values.twitterTitleAr !== updateCountryData.twitterTitle.ar) {
                formData.append('twitterTitle[ar]', values.twitterTitleAr);
                hasUpdates = true;
            }

            if (values.twitterDescriptionEn !== updateCountryData.twitterDescription.en) {
                formData.append('twitterDescription[en]', values.twitterDescriptionEn);
                hasUpdates = true;
            }

            if (values.twitterDescriptionAr !== updateCountryData.twitterDescription.ar) {
                formData.append('twitterDescription[ar]', values.twitterDescriptionAr);
                hasUpdates = true;
            }

            // Append file fields only if they have been updated
            if (imageUpdate) {
                formData.append('image', imageUpdate);
                hasUpdates = true;
            }

            if (ogImageUpdate) {
                formData.append('ogImage', ogImageUpdate);
                hasUpdates = true;
            }

            if (twitterImageUpdate) {
                formData.append('twitterImage', twitterImageUpdate);
                hasUpdates = true;
            }

            // If no updates were detected, skip the API call
            if (!hasUpdates) {
                toast.info('No changes detected. Update skipped.');
                return;
            }

            // Make the API call only if changes are detected
            const response = await axios.patch(
                `${process.env.REACT_APP_BACK_END}admin/countries/${updatingCountryId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();

            if (response.data.success) {
                formikUpdate.resetForm();
                setImageUpdate(null);
                setOgImageUpdate(null);
                setTwitterImageUpdate(null);
                toggleModalUpdate();
                toast.success('Country updated successfully!');
            }
        } catch (error) {
            setErrorUpdate(error?.response?.data?.message);
        }
    }

    let validateSchemaUpdate = Yup.object({
        name: Yup.string(),
        countryCode: Yup.string(),
        image: Yup.mixed().nullable(),

        // SEO Descriptions
        seoDescriptionEn: Yup.string(),
        seoDescriptionAr: Yup.string(),

        // SEO Keywords
        seoKeywordsEn: Yup.string(),
        seoKeywordsAr: Yup.string(),

        // OG Title and Descriptions
        ogTitleEn: Yup.string(),
        ogTitleAr: Yup.string(),
        ogDescriptionEn: Yup.string(),
        ogDescriptionAr: Yup.string(),

        // OG Image and URL
        ogImage: Yup.mixed().nullable(),
        ogUrl: Yup.string().url('Must be a valid URL'),

        // Twitter Metadata
        twitterCard: Yup.string(),
        twitterTitleEn: Yup.string(),
        twitterTitleAr: Yup.string(),
        twitterDescriptionEn: Yup.string(),
        twitterDescriptionAr: Yup.string(),
        twitterImage: Yup.mixed().nullable(),
    })

    let formikUpdate = useFormik({
        initialValues: {
            name: '',
            countryCode: '',
            image: null,
            seoDescriptionEn: '',
            seoDescriptionAr: '',
            seoKeywordsEn: '',
            seoKeywordsAr: '',
            ogTitleEn: '',
            ogTitleAr: '',
            ogDescriptionEn: '',
            ogDescriptionAr: '',
            ogImage: null,
            ogUrl: '',
            twitterCard: '',
            twitterTitleEn: '',
            twitterTitleAr: '',
            twitterDescriptionEn: '',
            twitterDescriptionAr: '',
            twitterImage: null,

        },
        validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateCountry

    })

    // end update country


    // delete country
    const [deleteCountryId, setDeleteCountryId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (country) => {
        setDeleteCountryId(country?.id); // Set the ID of the country being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update country
    async function submitDeleteCountry() {

        try {
            await axios.delete(
                `${process.env.REACT_APP_BACK_END}admin/countries/${deleteCountryId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('country delete successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }


    return <>

        <Helmet>
            <meta name='description' content='countries Page' charSet="utf-8" />
            <title>Countries</title>
        </Helmet>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for country..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        visible={true}
                    />
                </div> : allCountries.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortName}>
                                        Name
                                        <span className='ms-2'> {sortName ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    country Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    seo Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    seo Keywords
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Title
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ogUrl
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterCard
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterTitle
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterDescription
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterImage
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Deleted At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle Bin
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allCountries.map((country, index) => <tr key={country.id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {(currentPage - 1) * 10 + (index + 1)} {/** 10 => عدد العناصر في الصفحو الواحدة */}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {country.name}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {country.countryCode}
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(country?.image)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={country?.image} className='w-14 cursor-pointer h-16' alt={country.name} />
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className="mb-2 text-left">
                                        <span className="text-teal-700">EN:</span> {country.seoDescription.en}
                                    </h6>
                                    <h6 className="text-right">
                                        <span className="text-teal-700">العربية :</span> {country.seoDescription.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {country.seoKeywords.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {country?.seoKeywords?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {country.ogTitle.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {country?.ogTitle?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {country.ogDescription.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {country?.ogDescription?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(country?.ogImage)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={country?.ogImage} className='w-14 cursor-pointer h-16' alt={country.name} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <Link to={country.ogUrl} target='_blank' className="text-blue-500 hover:text-blue-700">{country.ogUrl}</Link>
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {country.twitterCard}
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {country.twitterTitle.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {country?.twitterTitle?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {country.twitterDescription.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {country?.twitterDescription?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(country?.twitterImage)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={country?.twitterImage} className='w-14 cursor-pointer h-16' alt={country.twitterCard} />
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {country.createdAt ? new Date(country.createdAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Created yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {country.updatedAt ? new Date(country.updatedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Updated yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {country.deletedAt ? new Date(country.deletedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Deleted yet"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModalUpdate}
                                        onClick={() => handleUpdateClick(country)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModelDel}
                                        onClick={() => handleDeleteClick(country)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>


        {/* Pagination Controls */}
        <div className="flex justify-center space-x-2 mt-4">
            {/* Previous Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>

            {/* Page Numbers */}
            {[...Array(totalPages)].map((_, i) => (
                <button
                    key={i}
                    className={`px-4 py-2 ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                        } hover:bg-gray-300 rounded`}
                    onClick={() => handlePageChange(i + 1)}
                >
                    {i + 1}
                </button>
            ))}

            {/* Next Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>


        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New country
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            {/* country Name */}
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">country Name <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type country name ex:Egypt.."
                                />
                                {formik.errors.name && formik.touched.name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.name}</div> : ''}
                            </div>

                            {/* country Code */}
                            <div className="col-span-2">
                                <label htmlFor="countryCode" className="block mb-2 text-sm font-medium text-gray-900">Country Code <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="countryCode"
                                    id="countryCode"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.countryCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type country Code ex:EG.."
                                />
                                {formik.errors.countryCode && formik.touched.countryCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.countryCode}</div> : ''}
                            </div>

                            {/* Image */}
                            <div className="col-span-2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handelImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.image && formik.touched.image && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.image}</div>}
                            </div>

                            {/* SEO DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Description En <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoDescriptionEn"
                                    id="seoDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (English) ..."
                                />
                                {formik.errors.seoDescriptionEn && formik.touched.seoDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoDescriptionEn}</div>}
                            </div>

                            {/* SEO DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Description Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoDescriptionAr"
                                    id="seoDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (Arabic) ..."
                                />
                                {formik.errors.seoDescriptionAr && formik.touched.seoDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoDescriptionAr}</div>}
                            </div>

                            {/* SEO KeywordsEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords En <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoKeywordsEn"
                                    id="seoKeywordsEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoKeywordsEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (English)..."
                                />
                                {formik.errors.seoKeywordsEn && formik.touched.seoKeywordsEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoKeywordsEn}</div>}
                            </div>

                            {/* SEO KeywordsAr */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoKeywordsAr"
                                    id="seoKeywordsAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoKeywordsAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (Arabic)..."
                                />
                                {formik.errors.seoKeywordsAr && formik.touched.seoKeywordsAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoKeywordsAr}</div>}
                            </div>

                            {/* OG TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleEn" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="ogTitleEn"
                                    id="ogTitleEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (English)..."
                                />
                                {formik.errors.ogTitleEn && formik.touched.ogTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogTitleEn}</div>}
                            </div>

                            {/* OG TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleAr" className="block mb-2 text-sm font-medium text-gray-900">OG Title Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="ogTitleAr"
                                    id="ogTitleAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (Arabic)..."
                                />
                                {formik.errors.ogTitleAr && formik.touched.ogTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogTitleAr}</div>}
                            </div>

                            {/* OG DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ogDescriptionEn"
                                    id="ogDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (English)..."
                                />
                                {formik.errors.ogDescriptionEn && formik.touched.ogDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogDescriptionEn}</div>}
                            </div>

                            {/* OG DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">OG Description AR <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ogDescriptionAr"
                                    id="ogDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (Arabic)..."
                                />
                                {formik.errors.ogDescriptionAr && formik.touched.ogDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogDescriptionAr}</div>}
                            </div>

                            {/* OG Image */}
                            <div className="col-span-2">
                                <label htmlFor="ogImage" className="block mb-2 text-sm font-medium text-gray-900">OG Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="ogImage"
                                    name="ogImage"
                                    type="file"
                                    onChange={(e) => {
                                        handelOgImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.ogImage && formik.touched.ogImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogImage}</div>}
                            </div>

                            {/* OG URL */}
                            <div className="col-span-2">
                                <label htmlFor="ogUrl" className="block mb-2 text-sm font-medium text-gray-900">OG URL <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="url"
                                    name="ogUrl"
                                    id="ogUrl"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogUrl}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG URL..."
                                />
                                {formik.errors.ogUrl && formik.touched.ogUrl && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogUrl}</div>}
                            </div>

                            {/* Twitter Card */}
                            <div className="col-span-2">
                                <label htmlFor="twitterCard" className="block mb-2 text-sm font-medium text-gray-900">Twitter Card <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterCard"
                                    id="twitterCard"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterCard}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter Card type..."
                                />
                                {formik.errors.twitterCard && formik.touched.twitterCard && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterCard}</div>}
                            </div>

                            {/* Twitter TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterTitleEn"
                                    id="twitterTitleEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (English)..."
                                />
                                {formik.errors.twitterTitleEn && formik.touched.twitterTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterTitleEn}</div>}
                            </div>

                            {/* Twitter TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title AR <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterTitleAr"
                                    id="twitterTitleAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (Arabic)..."
                                />
                                {formik.errors.twitterTitleAr && formik.touched.twitterTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterTitleAr}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="twitterDescriptionEn"
                                    id="twitterDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (English)..."
                                />
                                {formik.errors.twitterDescriptionEn && formik.touched.twitterDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterDescriptionEn}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="twitterDescriptionAr"
                                    id="twitterDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (Arabic)..."
                                />
                                {formik.errors.twitterDescriptionAr && formik.touched.twitterDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterDescriptionAr}</div>}
                            </div>

                            {/* Twitter Image */}
                            <div className="col-span-2">
                                <label htmlFor="twitterImage" className="block mb-2 text-sm font-medium text-gray-900">Twitter Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="twitterImage"
                                    name="twitterImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleTwitterImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.twitterImage && formik.touched.twitterImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterImage}</div>}
                            </div>
                        </div>

                        <button
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Add new country
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>

                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update country
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            {/* country Name */}
                            <div className="col-span-2">
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">country Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.name}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type country name ex: Egypt.."
                                />
                                {formikUpdate.errors.name && formikUpdate.touched.name ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.name}</div> : ''}
                            </div>

                            {/* country Code */}
                            <div className="col-span-2">
                                <label htmlFor="countryCode" className="block mb-2 text-sm font-medium text-gray-900">Country Code</label>
                                <input
                                    type="text"
                                    name="countryCode"
                                    id="countryCode"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.countryCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type country Code ex:EG.."
                                />
                                {formikUpdate.errors.countryCode && formikUpdate.touched.countryCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.countryCode}</div> : ''}
                            </div>

                            {/* New Image Upload */}
                            <div className="col-span-2">
                                <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">New Image</label>
                                <input
                                    id="image"
                                    name="image"
                                    type="file"
                                    onChange={(e) => {
                                        handleImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.image && formikUpdate.touched.image && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.image}</div>
                                )}
                            </div>

                            <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                                {imageUpdatePrivous && (
                                    <div>
                                        <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                        <img src={imageUpdatePrivous} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                                {imageUpdate && (
                                    <div>
                                        <label className="mb-4 text-sm font-medium text-gray-900">New Image Privous</label>
                                        <img src={URL.createObjectURL(imageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                            </div>


                            {/* SEO DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Description En</label>
                                <textarea
                                    name="seoDescriptionEn"
                                    id="seoDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description..."
                                />
                                {formikUpdate.errors.seoDescriptionEn && formikUpdate.touched.seoDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoDescriptionEn}</div>}
                            </div>

                            {/* SEO DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Description Ar</label>
                                <textarea
                                    name="seoDescriptionAr"
                                    id="seoDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description..."
                                />
                                {formikUpdate.errors.seoDescriptionAr && formikUpdate.touched.seoDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoDescriptionAr}</div>}
                            </div>

                            {/* SEO Keywords */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords En</label>
                                <textarea
                                    name="seoKeywordsEn"
                                    id="seoKeywordsEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoKeywordsEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (English)..."
                                />
                                {formikUpdate.errors.seoKeywordsEn && formikUpdate.touched.seoKeywordsEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoKeywordsEn}</div>}
                            </div>

                            {/* SEO Keywords */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords Ar</label>
                                <textarea
                                    name="seoKeywordsAr"
                                    id="seoKeywordsAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoKeywordsAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (English)..."
                                />
                                {formikUpdate.errors.seoKeywordsAr && formikUpdate.touched.seoKeywordsAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoKeywordsAr}</div>}
                            </div>

                            {/* OG TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleEn" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN</label>
                                <input
                                    type="text"
                                    name="ogTitleEn"
                                    id="ogTitleEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (English)..."
                                />
                                {formikUpdate.errors.ogTitleEn && formikUpdate.touched.ogTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogTitleEn}</div>}
                            </div>

                            {/* OG TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleAr" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN</label>
                                <input
                                    type="text"
                                    name="ogTitleAr"
                                    id="ogTitleAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (English)..."
                                />
                                {formikUpdate.errors.ogTitleAr && formikUpdate.touched.ogTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogTitleAr}</div>}
                            </div>

                            {/* OG Description */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN</label>
                                <textarea
                                    name="ogDescriptionEn"
                                    id="ogDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (English)..."
                                />
                                {formikUpdate.errors.ogDescriptionEn && formikUpdate.touched.ogDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogDescriptionEn}</div>}
                            </div>

                            {/* OG DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN</label>
                                <textarea
                                    name="ogDescriptionAr"
                                    id="ogDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (English)..."
                                />
                                {formikUpdate.errors.ogDescriptionAr && formikUpdate.touched.ogDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogDescriptionAr}</div>}
                            </div>

                            {/* OG Image */}
                            <div className="col-span-2">
                                <label htmlFor="ogImage" className="block mb-2 text-sm font-medium text-gray-900">OG Image</label>
                                <input
                                    id="ogImage"
                                    name="ogImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleOgImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.ogImage && formikUpdate.touched.ogImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogImage}</div>}
                            </div>

                            <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                                {ogImageUpdatePrivous && (
                                    <div>
                                        <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                        <img src={ogImageUpdatePrivous} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                                {ogImageUpdate && (
                                    <div>
                                        <label className="mb-4 text-sm font-medium text-gray-900">New Image Privous</label>
                                        <img src={URL.createObjectURL(ogImageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                            </div>


                            {/* OG URL */}
                            <div className="col-span-2">
                                <label htmlFor="ogUrl" className="block mb-2 text-sm font-medium text-gray-900">OG URL</label>
                                <input
                                    type="url"
                                    name="ogUrl"
                                    id="ogUrl"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogUrl}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG URL..."
                                />
                                {formikUpdate.errors.ogUrl && formikUpdate.touched.ogUrl && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogUrl}</div>}
                            </div>

                            {/* Twitter Card */}
                            <div className="col-span-2">
                                <label htmlFor="twitterCard" className="block mb-2 text-sm font-medium text-gray-900">Twitter Card</label>
                                <input
                                    type="text"
                                    name="twitterCard"
                                    id="twitterCard"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterCard}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter Card type..."
                                />
                                {formikUpdate.errors.twitterCard && formikUpdate.touched.twitterCard && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterCard}</div>}
                            </div>

                            {/* Twitter TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN</label>
                                <input
                                    type="text"
                                    name="twitterTitleEn"
                                    id="twitterTitleEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (English)..."
                                />
                                {formikUpdate.errors.twitterTitleEn && formikUpdate.touched.twitterTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterTitleEn}</div>}
                            </div>

                            {/* Twitter TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN</label>
                                <input
                                    type="text"
                                    name="twitterTitleAr"
                                    id="twitterTitleAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (English)..."
                                />
                                {formikUpdate.errors.twitterTitleAr && formikUpdate.touched.twitterTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterTitleAr}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description EN</label>
                                <textarea
                                    name="twitterDescriptionEn"
                                    id="twitterDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (English)..."
                                />
                                {formikUpdate.errors.twitterDescriptionEn && formikUpdate.touched.twitterDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterDescriptionEn}</div>}
                            </div>

                            {/* Twitter DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description Ar</label>
                                <textarea
                                    name="twitterDescriptionAr"
                                    id="twitterDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (English)..."
                                />
                                {formikUpdate.errors.twitterDescriptionAr && formikUpdate.touched.twitterDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterDescriptionAr}</div>}
                            </div>

                            {/* Twitter Image */}
                            <div className="col-span-2">
                                <label htmlFor="twitterImage" className="block mb-2 text-sm font-medium text-gray-900">Twitter Image</label>
                                <input
                                    id="twitterImage"
                                    name="twitterImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleTwitterImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.twitterImage && formikUpdate.touched.twitterImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterImage}</div>}
                            </div>
                        </div>

                        <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                            {twitterImageUpdatePrivous && (
                                <div>
                                    <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                    <img src={twitterImageUpdatePrivous} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                </div>
                            )}
                            {twitterImageUpdate && (
                                <div>
                                    <label className="mb-4 text-sm font-medium text-gray-900">New Image Privous</label>
                                    <img src={URL.createObjectURL(twitterImageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                </div>
                            )}
                        </div>


                        <button
                            disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className=" mt-4 text-white inline-flex items-center bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Update country
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-[80%] xl:w-[45%] max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Delete this country ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteCountry}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>


        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden overflow-auto fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-[80%] xl:w-[50%] h-[95vh] xl:h-[100vh]">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />

                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 mb-1 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
