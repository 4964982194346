import React from 'react'
import LoginWithEmail from '../LoginWithEmail/LoginWithEmail.jsx'
import logo from '../../Assets/images/couponesta.jpg'
import { Helmet } from 'react-helmet';

export default function Login() {

    return <>

        <Helmet>
            <meta name='description' content='Login Page' charSet="utf-8" />
            <title>Login</title>
        </Helmet>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <img
                    className="mx-auto h-20 rounded-xl  w-auto"
                    src={logo}
                    alt="Your Company"
                />

                <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign in to your account
                </h2>
            </div>

            <LoginWithEmail />

        </div>
    </>
}
