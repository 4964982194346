import React from 'react';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute(props) {
    if (localStorage.getItem('refresh_token') && localStorage.getItem('refresh_token').trim() !== '' && localStorage.getItem('refresh_token') !== null && localStorage.getItem('refresh_token') !== undefined) {


        return props.children
    } else {

        return <Navigate to={'/login'} />
    }
}
