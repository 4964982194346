import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import { ThemeProvider } from "@material-tailwind/react";


// font awesome
import '@fortawesome/fontawesome-free/css/all.min.css';

// react-query
let queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <QueryClientProvider client={queryClient}>

    <ThemeProvider>

      <App />
      <Toaster />

    </ThemeProvider>

  </QueryClientProvider>

);
