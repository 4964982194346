import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import UserContextProvider from './Context/UserContext.js';
import Layout from './components/Layout/Layout.jsx';
import Login from './components/Login/Login.jsx';
import LoginWithEmail from './components/LoginWithEmail/LoginWithEmail.jsx';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.jsx';
import Home from './components/Home/Home.jsx';
import Notfound from './components/Notfound/Notfound.jsx';
import Category from './components/Category/Category.jsx';
import Brand from './components/Brands/Brand.jsx';
import Ads from './components/Ads/Ads.jsx';
import Coupon from './components/Coupon/Coupon.jsx';
import Notification from './components/Notification/Notification.jsx';
import User from './components/User/User.jsx';
import BarcharMonth from './components/BarcharMonth/BarcharMonth.jsx';
import PieChar from './components/PieChar/PieChar.jsx';
import NewPassword from './components/NewPassword/NewPassword.jsx';
import ForgetPassword from './components/ForgetPassword/ForgetPassword.jsx';
import ForgetPasswordEmail from './components/ForgetPasswordEmail/ForgetPasswordEmail.jsx';
import ForgetPasswordPhone from './components/ForgetPasswordPhone/ForgetPasswordPhone.jsx';
import ConfirmationEmail from './components/ConfirmationEmail/ConfirmationEmail.jsx';
import BarcharDaly from './components/Barchar Daly/BarcharDaly.jsx';
import Country from './components/Country/Country.jsx';

let routers = createHashRouter([

  {
    path: 'login', element: <Login />, children: [
      { index: true, element: <LoginWithEmail /> },
    ],

  },


  { path: 'newPassword/:email', element: <NewPassword /> },

  {
    path: 'forgetPassword', element: <ForgetPassword />, children: [
      { index: true, element: <ForgetPasswordEmail /> },
      { path: 'withPhone', element: <ForgetPasswordPhone /> },
    ],

  },

  { path: 'confirmationEmail/:email', element: <ConfirmationEmail /> },



  {
    path: '/', element: <ProtectedRoute> <Layout /> </ProtectedRoute>, children: [
      { index: true, element: <ProtectedRoute> <Home /> </ProtectedRoute> },
      { path: 'country', element: <ProtectedRoute> <Country /> </ProtectedRoute> },

      { path: 'category', element: <ProtectedRoute> <Category /> </ProtectedRoute> },

      { path: 'brand', element: <ProtectedRoute> <Brand /> </ProtectedRoute> },

      { path: 'ads', element: <ProtectedRoute> <Ads /> </ProtectedRoute> },

      { path: 'coupon', element: <ProtectedRoute> <Coupon /> </ProtectedRoute> },

      { path: 'notification', element: <ProtectedRoute> <Notification /> </ProtectedRoute> },

      { path: 'user', element: <ProtectedRoute> <User /> </ProtectedRoute> },

      { path: 'barcharMonth', element: <ProtectedRoute> <BarcharMonth /> </ProtectedRoute> },

      { path: 'barcharDaly', element: <ProtectedRoute> <BarcharDaly /> </ProtectedRoute> },

      { path: 'piechar', element: <ProtectedRoute> <PieChar /> </ProtectedRoute> },

      { path: '*', element: <Notfound /> },
    ]
  }
])


function App() {


  return <UserContextProvider>

    <RouterProvider router={routers}></RouterProvider>

  </UserContextProvider>

}

export default App;
