import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from 'react-query';
import axios from 'axios';

export default function BarcharDaly() {
    // Fetch data from the backend
    async function getChartDay() {
        const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/dashboard/line-chart-days`);
        return response.data;
    }

    const { data } = useQuery('ChartDay', getChartDay, {
        cacheTime: 9000000
    });

    let allChartDay = [];

    if (data?.message === "Request successful") {
        const dates = data.data.coupons.keys;
        const couponValues = data.data.coupons.values;
        const userValues = data.data.users.values;

        allChartDay = dates.map((date, index) => ({
            date,
            coupons: couponValues[index],
            users: userValues[index]
        }));
    }

    return (
        <div className="container-fluid md:container mx-auto py-1.5 md:h-full h-96 w-full pb-16">
            <h1 className='text-center text-teal-500 font-extrabold mt-24 md:mt-0 text-xl xl:text-3xl'>Coupon & User Data by Day</h1>

            <ResponsiveBar
                data={allChartDay}
                keys={['coupons', 'users']}
                indexBy="date"
                margin={{ top: 50, right: 20, bottom: 80, left: 50 }}
                padding={0.15}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set1' }}
                groupMode="grouped"
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: window.innerWidth < 768 ? -70 : -60,  // Rotate dynamically
                    legend: 'Date',
                    legendPosition: 'middle',
                    legendOffset: 75,
                    tickValues: window.innerWidth < 768 ? 'every 5 days' : undefined,  // Show fewer ticks on small screens
                    format: value => {
                        const [year, month, day] = value.split('/');
                        return `${day}/${month}/${year}`;
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={window.innerWidth < 768 ? 50 : 12}  // Skip labels on small screens
                labelSkipHeight={window.innerWidth < 768 ? 50 : 12} // Skip labels on small screens
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                legends={window.innerWidth >= 768 ? [
                    {
                        dataFrom: 'keys',
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ] : []}  // Hide legends on small screens
                role="application"
                ariaLabel="Coupon and user data bar chart"
                barAriaLabel={e => `${e.id}: ${e.formattedValue} on date: ${e.indexValue}`}
            />
        </div>
    );
}
