import axios from 'axios';
import React, { useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

export default function User() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)
    const [totalPages, setTotalPages] = useState(1); // Total available pages
    const [currentPage, setCurrentPage] = useState(1); // Current page

    let navigate = useNavigate();

    const [sortName, setSortName] = useState(false);
    const ToggleSortName = () => {
        setSortName(!sortName);
        refetch()
    };

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage); // Change current page
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };


    async function getAllUsers() {

        const searchParams = searchQuery ? `&search=${searchQuery}&searchField=fullName` : "";
        const sortParams = sortName ? "&sortBy=fullName&sortOrder=DESC" : "";

        try {
            let response = await axios.get(
                `${process.env.REACT_APP_BACK_END}admin/users?page=${currentPage}${searchParams}${sortParams}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                        'Accept-Language': '',
                    }
                }
            );

            setTotalPages(response?.data?.totalPages);

            return response;

        } catch (error) {
            // Check if the error is 401
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('refresh_token');
                navigate('/login');
            } else {
                throw error;
            }
        }
    }

    let { isLoading, data, refetch } = useQuery(
        ['AllUsers', currentPage, searchQuery, sortName],
        () => getAllUsers(),
        {
            cacheTime: 9000000,            // Cache the data for a long time (milliseconds)
            staleTime: 9000000,            // Consider the data fresh for a long time
            refetchOnWindowFocus: false,   // Don't refetch when the window gains focus
            refetchOnMount: false,         // Don't refetch when the component mounts
            refetchOnReconnect: false,     // Don't refetch on reconnect
            refetchInterval: false         // Disable polling
        }
    );


    let allUsers = []

    if (data?.data?.success) {

        allUsers = data?.data?.data
    }

    // modal
    const toggleModal = async () => {
        const modal = document.getElementById('crud-modal');
        modal.classList.toggle('hidden');
        modal.setAttribute('aria-hidden', modal.classList.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');


    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add user
    const [image, setImage] = useState(null); // Use null instead of empty string for files
    function handelImage(e) {
        setImage(e.target.files[0])
    }


    // Function to submit add user
    async function submitAddUsers(values) {


        const formData = new FormData();
        formData.append('fullName', values.fullName);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('role', values.role);
        formData.append('status', values.status);
        formData.append('gender', values.gender);
        formData.append('phoneNumber', values.phoneNumber);
        formData.append('phoneNumberCountryCode', values.phoneNumberCountryCode);
        formData.append('avatar', image);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}admin/users`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                }
            }
            );

            await refetch();

            if (response.data.success) {
                formik.resetForm();
                toggleModal();
                toast.success('User added successfully!');
            }

        } catch (error) {
            setError(error?.response?.data?.errors?.map(elm => elm.message));
        }

    }

    let validateSchemaAdd = Yup.object({
        fullName: Yup.string().min(1).max(100).required("Full Name is required"),
        email: Yup.string().email('Invalid email format').required("Email is required"),
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
        role: Yup.string().oneOf(['User', 'Admin', 'Employee']).required(),
        status: Yup.string().oneOf(['offline', 'online', 'blocked']).required(),
        gender: Yup.string().oneOf(['male', 'female']).required(),
        phoneNumber: Yup.string().required("phoneNumber is required"),
        phoneNumberCountryCode: Yup.string().min(2, "must be longer than or equal to 2 characters").max(3, "must be shorter than or equal to 3 characters").required("phoneNumberCountryCode is required"),
        avatar: Yup.mixed().required('avatar is required'),
    })

    let formik = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            role: '',
            status: '',
            gender: '',
            phoneNumber: '',
            phoneNumberCountryCode: '',
            avatar: null,
        }, validationSchema: validateSchemaAdd,
        onSubmit: submitAddUsers

    })


    // update user
    const [updateUserData, setUpdateUserData] = useState(null);
    const [updatingUserId, setUpdatingUserId] = useState(null);

    const [imageUpdate, setImageUpdate] = useState(null); // Use null instead of empty string for files
    const [imageUpdatePrivous, setImageUpdatePrivous] = useState(null);

    const handleImageUpdate = (e) => {
        const file = e.target.files[0];
        setImageUpdate(file);
    };

    // Function to handle update button click
    const handleUpdateClick = (user) => {

        setUpdateUserData(user);
        setUpdatingUserId(user.id);

        setImageUpdatePrivous(user.image); // Assuming country.imageUrl holds the URL of the existing image


        // Set Formik initial values based on the user data
        formikUpdate.setValues({
            fullName: user.fullName || '',
            email: user.email || '',
            password: '',
            role: user.role || '',
            status: user.status || '',
            gender: user.gender || '',
            phoneNumber: user.phoneNumber || '',
            phoneNumberCountryCode: user.phoneNumberCountryCode || '',
            avatar: null,
        });

        toggleModalUpdate();

    };


        async function submitUpdateUser(values) {
        try {
            let hasUpdates = false; // Flag to check if any field is updated
            const formData = new FormData();


            // Compare each field and append to formData only if it has changed
            if (values.fullName !== updateUserData.fullName) {
                formData.append('fullName', values.fullName);
                hasUpdates = true;
            }

            if (values.email !== updateUserData.email) {
                formData.append('email', values.email);
                hasUpdates = true;
            }

            // Append password only if it's not empty (indicating a change)
            if (values.password) {
                formData.append('password', values.password);
                hasUpdates = true;
            }

            if (values.role !== updateUserData.role) {
                formData.append('role', values.role);
                hasUpdates = true;
            }

            if (values.status !== updateUserData.status) {
                formData.append('status', values.status);
                hasUpdates = true;
            }

            if (values.gender !== updateUserData.gender) {
                formData.append('gender', values.gender);
                hasUpdates = true;
            }

            if (values.phoneNumber !== updateUserData.phoneNumber) {
                formData.append('phoneNumber', values.phoneNumber);
                hasUpdates = true;
            }

            if (values.phoneNumberCountryCode !== updateUserData.phoneNumberCountryCode) {
                formData.append('phoneNumberCountryCode', values.phoneNumberCountryCode);
                hasUpdates = true;
            }

            // Append avatar only if it has been updated
            if (imageUpdate) {
                formData.append('avatar', imageUpdate);
                hasUpdates = true;
            }

            // If no changes were detected, skip the API call
            if (!hasUpdates) {
                toast.info('No changes detected. Update skipped.');
                return;
            }

            // Make the API call only if changes are detected
            const response = await axios.patch(
                `${process.env.REACT_APP_BACK_END}admin/users/${updatingUserId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();

            if (response.data.success) {
                formikUpdate.resetForm();
                setImageUpdate(null);
                toggleModalUpdate();
                toast.success('User updated successfully!');
            }
        } catch (error) {
            setErrorUpdate(error?.response?.data?.message);
        }
    }

    let validateSchemaUpdate = Yup.object({
        fullName: Yup.string().min(1).max(100),
        email: Yup.string().email('Invalid email format'),
        password: Yup.string().min(6, 'Password must with character and minLength 6'),
        role: Yup.string().oneOf(['User', 'Admin', 'Employee']),
        status: Yup.string().oneOf(['offline', 'online', 'blocked']),
        gender: Yup.string().oneOf(['male', 'female']),
        phoneNumber: Yup.string(),
        phoneNumberCountryCode: Yup.string().min(2, "must be longer than or equal to 2 characters").max(3, "must be shorter than or equal to 3 characters"),
        avatar: Yup.mixed().nullable(),
    })

    let formikUpdate = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            password: '',
            role: '',
            status: '',
            gender: '',
            phoneNumber: '',
            phoneNumberCountryCode: '',
            avatar: null,
        },
        validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateUser

    })

    // end update user


    // delete user
    const [deleteUserId, setDeleteUserId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (user) => {
        setDeleteUserId(user?.id); // Set the ID of the user being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update user
    async function submitDeleteUsers() {

        try {
            await axios.delete(
                `${process.env.REACT_APP_BACK_END}admin/users/${deleteUserId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('user delete successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }


    return <>

        <Helmet>
            <meta name='description' content='users Page' charSet="utf-8" />
            <title>users</title>
        </Helmet>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for user..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassNameclassName=""
                        visible={true}
                    />
                </div> : allUsers.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortName}>
                                        fullName
                                        <span className='ms-2'> {sortName ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    phoneNumber
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    phoneNumberCountryCode
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    userLocale
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Avatar
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    gender
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    role
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    confirmAccount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    provider
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Deleted At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle Bin
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allUsers?.map((user, index) => <tr key={user.id} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {(currentPage - 1) * 10 + (index + 1)}
                                </th>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.fullName}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <div className='flex  justify-between items-center'>
                                        <div>{user.email}</div>
                                        <Link to={`https://mail.google.com/mail/?view=cm&fs=1&to=${user.email}`} target='_blank'
                                            className="ms-2 flex justify-center items-center w-10 h-10 rounded-full text-white bg-[#8a8a8a] hover:bg-gray-500">
                                            <i className='fa-regular fa-envelope text-xl'></i>
                                        </Link>
                                    </div>
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.phoneNumber}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.phoneNumberCountryCode}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.userLocale}
                                </td>
                                <td className="px-3 py-4">
                                    <img
                                        onClick={() => handleImageClick(user?.avatar)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={user?.avatar} className='w-14 cursor-pointer h-16' alt={user.fullName} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.gender}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.role === "Admin" ? <span className='text-green-700 font-semibold'>
                                        Admin
                                        <i className="ms-1 fa fa-check-circle"></i>
                                    </span> :
                                        user.role === "Employee" ? <span className='text-gray-700 font-semibold'>
                                            Employee
                                            <i className="ms-1 fa fa-check-circle"></i>
                                        </span> : <span className='text-orange-700'>User</span>}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.confirmAccount ? <span className='text-green-700 font-semibold'>
                                        Yes
                                        <i className="ms-1 fa fa-check-circle"></i>
                                    </span> : "No"}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.status}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {user.provider}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {user.createdAt ? new Date(user.createdAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Created yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {user.updatedAt ? new Date(user.updatedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Updated yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {user.deletedAt ? new Date(user.deletedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Deleted yet"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModalUpdate}
                                        onClick={() => handleUpdateClick(user)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModelDel}
                                        onClick={() => handleDeleteClick(user)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>


        {/* Pagination Controls */}
        <div className="flex justify-center space-x-2 mt-4">
            {/* Previous Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>

            {/* Page Numbers */}
            {[...Array(totalPages)].map((_, i) => (
                <button
                    key={i}
                    className={`px-4 py-2 ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                        } hover:bg-gray-300 rounded`}
                    onClick={() => handlePageChange(i + 1)}
                >
                    {i + 1}
                </button>
            ))}

            {/* Next Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>


        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New user
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 ">Full Name <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.fullName}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Full Name..."
                                />

                                {formik.errors.fullName && formik.touched.fullName ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.fullName}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    id='email'
                                    name='email'
                                    type='email'
                                    autoComplete="email"
                                    placeholder='Type Email hear Ex: example@gmail.com...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formik.errors.email && formik.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.email}</div> : ''}
                            </div>


                            <div className="flex justify-between align-middle mt-1 gap-x-3 col-span-2">

                                <div className='w-full'>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phoneNumberCountryCode <span className='text-red-600 font-extrabold '>*</span>
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.phoneNumberCountryCode}
                                            id='phoneNumberCountryCode'
                                            name='phoneNumberCountryCode'
                                            type='tel'
                                            autoComplete="phoneNumberCountryCode"
                                            placeholder='Type a country code Ex:EG...'
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>

                                <div className='w-full'>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phone number <span className='text-red-600 font-extrabold '>*</span>
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.phoneNumber}
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            type='text'
                                            placeholder='Type a Phone Number...'
                                            autoComplete="phoneNumber"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>
                            </div>

                            {formik.errors.phoneNumberCountryCode && formik.touched.phoneNumberCountryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.phoneNumberCountryCode}</div> : ''}
                            {formik.errors.phoneNumber && formik.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.phoneNumber}</div> : ''}


                            <div className="col-span-2">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    id='password'
                                    name='password'
                                    type='text'
                                    placeholder='Type password hear...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}
                            </div>

                            {/* role */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Role <span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="User"
                                            name="role"
                                            value="User"
                                            checked={formik.values.role === "User"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>User</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Admin"
                                            name="role"
                                            value="Admin"
                                            checked={formik.values.role === "Admin"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Admin</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Employee"
                                            name="role"
                                            value="Employee"
                                            checked={formik.values.role === "Employee"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Employee</span>
                                    </label>
                                </div>
                                {formik.errors.role && formik.touched.role ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.role}
                                    </div>
                                ) : ''}
                            </div>

                            {/* status */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User status<span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="offline"
                                            name="status"
                                            value="offline"
                                            checked={formik.values.status === "offline"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>offline</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="online"
                                            name="status"
                                            value="online"
                                            checked={formik.values.status === "online"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>online</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="blocked"
                                            name="status"
                                            value="blocked"
                                            checked={formik.values.status === "blocked"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>blocked</span>
                                    </label>
                                </div>
                                {formik.errors.status && formik.touched.status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.status}
                                    </div>
                                ) : ''}
                            </div>

                            {/* gender */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Gender <span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value="male"
                                            checked={formik.values.gender === "male"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>male</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            checked={formik.values.gender === "female"}
                                            onChange={formik.handleChange}
                                            className="mr-2"
                                        />
                                        <span>female</span>
                                    </label>

                                </div>
                                {formik.errors.gender && formik.touched.gender ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formik.errors.gender}
                                    </div>
                                ) : ''}
                            </div>

                            {/* Avatar */}
                            <div className="col-span-2">
                                <label htmlFor="avatar" className="block mb-2 text-sm font-medium text-gray-900">avatar <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="avatar"
                                    name="avatar"
                                    type="file"
                                    onChange={(e) => {
                                        handelImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.avatar && formik.touched.avatar && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.avatar}</div>}
                            </div>

                        </div>

                        <button
                            // disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >

                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Add new user
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>

                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update User
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                                <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-gray-900 ">Full Name <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="fullName"
                                    id="fullName"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.fullName}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Full Name..."
                                />

                                {formikUpdate.errors.fullName && formikUpdate.touched.fullName ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.fullName}</div> : ''}
                            </div>

                            <div className="col-span-2">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                                <input
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.email}
                                    id='email'
                                    name='email'
                                    type='email'
                                    autoComplete="email"
                                    placeholder='Type Email hear Ex: example@gmail.com...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formikUpdate.errors.email && formikUpdate.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.email}</div> : ''}
                            </div>


                            <div className="flex justify-between align-middle mt-1 gap-x-3 col-span-2">

                                <div className='w-full'>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phoneNumberCountryCode
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formikUpdate.handleBlur}
                                            onChange={formikUpdate.handleChange}
                                            value={formikUpdate.values.phoneNumberCountryCode}
                                            id='phoneNumberCountryCode'
                                            name='phoneNumberCountryCode'
                                            type='tel'
                                            autoComplete="phoneNumberCountryCode"
                                            placeholder='Type a country code Ex:EG...'
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>

                                <div className='w-full'>
                                    <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                                        phone number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            onBlur={formikUpdate.handleBlur}
                                            onChange={formikUpdate.handleChange}
                                            value={formikUpdate.values.phoneNumber}
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            type='text'
                                            placeholder='Type a Phone Number...'
                                            autoComplete="phoneNumber"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />

                                    </div>
                                </div>
                            </div>

                            {formikUpdate.errors.phoneNumberCountryCode && formikUpdate.touched.phoneNumberCountryCode ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.phoneNumberCountryCode}</div> : ''}
                            {formikUpdate.errors.phoneNumber && formikUpdate.touched.phoneNumber ? <div className="py-3 mt-1 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.phoneNumber}</div> : ''}


                            <div className="col-span-2">
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Password <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.password}
                                    id='password'
                                    name='password'
                                    type='text'
                                    placeholder='Type password hear...'
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                />
                                {formikUpdate.errors.password && formikUpdate.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.password}</div> : ''}
                            </div>

                            {/* role */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Role <span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="User"
                                            name="role"
                                            value="User"
                                            checked={formikUpdate.values.role === "User"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>User</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Admin"
                                            name="role"
                                            value="Admin"
                                            checked={formikUpdate.values.role === "Admin"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Admin</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="Employee"
                                            name="role"
                                            value="Employee"
                                            checked={formikUpdate.values.role === "Employee"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>Employee</span>
                                    </label>
                                </div>
                                {formikUpdate.errors.role && formikUpdate.touched.role ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.role}
                                    </div>
                                ) : ''}
                            </div>

                            {/* status */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User status<span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="offline"
                                            name="status"
                                            value="offline"
                                            checked={formikUpdate.values.status === "offline"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>offline</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="online"
                                            name="status"
                                            value="online"
                                            checked={formikUpdate.values.status === "online"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>online</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="blocked"
                                            name="status"
                                            value="blocked"
                                            checked={formikUpdate.values.status === "blocked"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>blocked</span>
                                    </label>
                                </div>
                                {formikUpdate.errors.status && formikUpdate.touched.status ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.status}
                                    </div>
                                ) : ''}
                            </div>

                            {/* gender */}
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900">User Gender <span className='text-red-600 font-extrabold '>*</span></label>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value="male"
                                            checked={formikUpdate.values.gender === "male"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>male</span>
                                    </label>

                                    <label className="inline-flex items-center ml-4 cursor-pointer">
                                        <input
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            checked={formikUpdate.values.gender === "female"}
                                            onChange={formikUpdate.handleChange}
                                            className="mr-2"
                                        />
                                        <span>female</span>
                                    </label>

                                </div>
                                {formikUpdate.errors.gender && formikUpdate.touched.gender ? (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">
                                        {formikUpdate.errors.gender}
                                    </div>
                                ) : ''}
                            </div>

                            {/* New Avatar Upload */}
                            <div className="col-span-2">
                                <label htmlFor="avatar" className="block mb-2 text-sm font-medium text-gray-900">New Avatar</label>
                                <input
                                    id="avatar"
                                    name="avatar"
                                    type="file"
                                    onChange={(e) => {
                                        handleImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.avatar && formikUpdate.touched.avatar && (
                                    <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.avatar}</div>
                                )}
                            </div>

                            <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                                {imageUpdatePrivous && (
                                    <div>
                                        <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                        <img src={imageUpdatePrivous} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                                {imageUpdate && (
                                    <div>
                                        <label className="mb-4 text-sm font-medium text-gray-900">New Image Privous</label>
                                        <img src={URL.createObjectURL(imageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                            </div>

                        </div>

                        <button
                            disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800"
                        >

                            <svg className="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            Update User
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>


                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-[80%] xl:w-[45%] max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Delete this user ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteUsers}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>


        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden overflow-auto fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-[80%] xl:w-[50%] h-[95vh] xl:h-[100vh]">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />

                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 mb-1 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
