import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';


const fetchData = async (url, token, setFunction, setLoading) => {
    setLoading(true);
    try {
        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setFunction(response.data.data);
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
    } finally {
        setLoading(false);
    }
};

export default function Coupon() {

    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [error, setError] = useState(null)
    const [errorUpdate, setErrorUpdate] = useState(null)
    const [totalPages, setTotalPages] = useState(1); // Total available pages
    const [currentPage, setCurrentPage] = useState(1); // Current page

    const [searchQueryBrands, setSearchQueryBrands] = useState('');
    const [searchQueryCategories, setSearchQueryCategories] = useState('');
    const [searchQueryCountries, setSearchQueryCountries] = useState('');

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const [loadingBrands, setLoadingBrands] = useState(false);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);


    const backendUrl = process.env.REACT_APP_BACK_END;
    const token = localStorage.getItem('refresh_token');

    let navigate = useNavigate();

    const [sortName, setSortName] = useState(false);
    const ToggleSortName = () => {
        setSortName(!sortName);
        refetch()
    };

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage); // Change current page
    };

    const handleInputChange = (event) => { // Function to handle input change
        setSearchQuery(event.target.value); // Update searchQuery state with input value
    };

    const handleSearchCategoryChange = (event) => { // Function to handle input change
        setSearchQueryCategories(event.target.value); // Update searchQuery state with input value
    };

    const handleSearchCountryChange = (event) => { // Function to handle input change
        setSearchQueryCountries(event.target.value); // Update searchQuery state with input value
    };

    const handleSearchBrandChange = (event) => { // Function to handle input change
        setSearchQueryBrands(event.target.value); // Update searchQuery state with input value
    };




    async function getAllCoupons() {


        const searchParams = searchQuery ? `&search=${searchQuery}&searchField=name` : "";
        const sortParams = sortName ? "&sortBy=name&sortOrder=DESC" : "";

        try {
            let response = await axios.get(
                `${process.env.REACT_APP_BACK_END}admin/coupons?page=${currentPage}${searchParams}${sortParams}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                        'Accept-Language': '',
                    }
                }
            );

            setTotalPages(response?.data?.totalPages);

            return response;

        } catch (error) {
            // Check if the error is 401
            if (error.response && error.response.status === 401) {
                // Remove token from local storage
                localStorage.removeItem('refresh_token');
                // Redirect to login page
                navigate('/login');
            } else {
                // If it's not a 401 error, throw it so the app can handle it elsewhere
                throw error;
            }
        }
    }

    let { isLoading, data, refetch } = useQuery(
        ['AllCoupons', currentPage, searchQuery, sortName],
        () => getAllCoupons(),
        {
            cacheTime: 9000000,            // Cache the data for a long time (milliseconds)
            staleTime: 9000000,            // Consider the data fresh for a long time
            refetchOnWindowFocus: false,   // Don't refetch when the window gains focus
            refetchOnMount: false,         // Don't refetch when the component mounts
            refetchOnReconnect: false,     // Don't refetch on reconnect
            refetchInterval: false         // Disable polling
        }
    );


    let allCoupons = []

    if (data?.data?.success) {

        allCoupons = data?.data?.data
    }

    // modal
    const toggleModal = async () => {
        await Promise.all([
            fetchData(`${backendUrl}admin/countries?limit=5`, token, setCountries, setLoadingCountries),
            fetchData(`${backendUrl}admin/categories?limit=5`, token, setCategories, setLoadingCategories),
            fetchData(`${backendUrl}admin/brands?limit=5`, token, setBrands, setLoadingBrands)
        ]);
        const modal = document?.getElementById('crud-modal');
        modal?.classList?.toggle('hidden');
        modal?.setAttribute('aria-hidden', modal?.classList?.contains('hidden'));
    };

    const toggleModalUpdate = () => {
        const modalUpdate = document?.getElementById('crud-modal-update');
        modalUpdate?.classList?.toggle('hidden');
        modalUpdate?.setAttribute('aria-hidden', modalUpdate?.classList?.contains('hidden'));
    };

    const toggleModelDel = () => {
        const modalDelete = document.getElementById('popup-modal');
        modalDelete.classList.toggle('hidden');
        modalDelete.setAttribute('aria-hidden', modalDelete.classList.contains('hidden'));
    }


    const toggleModelImg = () => {
        const modalImage = document.getElementById('popup-modal-image');
        modalImage.classList.toggle('hidden');
        modalImage.setAttribute('aria-hidden', modalImage.classList.contains('hidden'));
    }

    // Function to handle Image button click
    const [imageSrc, setImageSrc] = useState('');


    const handleImageClick = (img) => {
        setImageSrc(img); // Set the src of the Brand being image
        toggleModelImg(); // Show the img modal
    };


    // add coupon
    const [ogImage, setOgImage] = useState(null); // Use null instead of empty string for files
    const [twitterImage, setTwitterImage] = useState(null); // Use null instead of empty string for files

    function handelOgImage(e) {
        setOgImage(e.target.files[0])
    }

    function handleTwitterImage(e) {
        setTwitterImage(e.target.files[0])
    }


    const handleCheckboxChange = useCallback((setSelected, id) => {
        setSelected(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(item => item !== id)
                : [...prevSelected, id]
        );
    }, []);
    const debouncedFetchBrands = useCallback(
        debounce(async (search) => {
            const query = search ? `&search=${search}&searchField=name` : '';
            await fetchData(`${backendUrl}admin/brands?limit=5${query}`, token, setBrands, setLoadingBrands);
        }, 300),
        [token, backendUrl]
    );

    const debouncedFetchCategories = useCallback(
        debounce(async (search) => {
            const query = search ? `&search=${search}&searchField=name` : '';
            await fetchData(`${backendUrl}admin/categories?limit=5${query}`, token, setCategories, setLoadingCategories);
        }, 300),
        [token, backendUrl]
    );

    const debouncedFetchCountries = useCallback(
        debounce(async (search) => {
            const query = search ? `&search=${search}&searchField=name` : '';
            await fetchData(`${backendUrl}admin/countries?limit=5${query}`, token, setCountries, setLoadingCountries);
        }, 300),
        [token, backendUrl]
    );

    useEffect(() => {
        debouncedFetchBrands(searchQueryBrands);
    }, [searchQueryBrands, debouncedFetchBrands]);

    useEffect(() => {
        debouncedFetchCategories(searchQueryCategories);
    }, [searchQueryCategories, debouncedFetchCategories]);

    useEffect(() => {
        debouncedFetchCountries(searchQueryCountries);
    }, [searchQueryCountries, debouncedFetchCountries]);

    async function submitAddCoupons(values) {
        const formData = new FormData();
        formData.append('name[en]', values.nameEn);
        formData.append('name[ar]', values.nameAr);
        formData.append('description[en]', values.couponDescriptionEn);
        formData.append('description[ar]', values.couponDescriptionAr);
        formData.append('status[en]', values.couponStatusEn);
        formData.append('status[ar]', values.couponStatusAr);
        formData.append('link', values.link);

        // const formattedDate = new Date(values.expire).toISOString();
        // formData.append('expire', formattedDate);
        formData.append('code', values.code);
        formData.append('amount', values.amount);
        formData.append('qrCode', values.qrCode);

        // Localized SEO and Social Media fields
        formData.append('seoDescription[en]', values.seoDescriptionEn);
        formData.append('seoDescription[ar]', values.seoDescriptionAr);
        formData.append('seoKeywords[en]', values.seoKeywordsEn);
        formData.append('seoKeywords[ar]', values.seoKeywordsAr);

        // OG Fields
        formData.append('ogTitle[en]', values.ogTitleEn);
        formData.append('ogTitle[ar]', values.ogTitleAr);
        formData.append('ogDescription[en]', values.ogDescriptionEn);
        formData.append('ogDescription[ar]', values.ogDescriptionAr);
        formData.append('ogImage', ogImage);
        formData.append('ogUrl', values.ogUrl);

        // Twitter Fields
        formData.append('twitterCard', values.twitterCard);
        formData.append('twitterTitle[en]', values.twitterTitleEn);
        formData.append('twitterTitle[ar]', values.twitterTitleAr);
        formData.append('twitterDescription[en]', values.twitterDescriptionEn);
        formData.append('twitterDescription[ar]', values.twitterDescriptionAr);
        formData.append('twitterImage', twitterImage);

        selectedCategories.forEach((categoryId, index) => {
            formData.append(`categoryIds[${index}]`, categoryId);
        });

        selectedCountries.forEach((countryId, index) => {
            formData.append(`countryIds[${index}]`, countryId);
        });


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}admin/coupons`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                }
            }
            );

            await refetch();

            if (response.data.success) {
                formik.resetForm();
                toggleModal();
                toast.success('coupon added successfully!');
            }

        } catch (error) {

            setError(error?.response?.data?.message);
        }
    }


    let validateSchemaAdd = Yup.object({
        nameEn: Yup.string().required('coupon name (EN) is required'),
        nameAr: Yup.string().required('coupon name (AR) is required'),
        couponDescriptionEn: Yup.string().required('coupon description (EN) is required'),
        couponDescriptionAr: Yup.string().required('coupon description (AR) is required'),
        statusEn: Yup.string().required('coupon status (EN) is required'),
        statusAr: Yup.string().required('coupon status (AR) is required'),
        qrCode: Yup.string().required('coupon qrCode is required'),
        code: Yup.string(),
        amount: Yup.number().min(0).required('coupon amount is required'),
        link: Yup.string().url('Must be a valid URL').required('link is required'),
        expire: Yup.date().required('expiration date is required'),
        categoryIds: Yup.array(),
        countryIds: Yup.array(),
        brandIds: Yup.array(),

        // SEO Descriptions
        seoDescriptionEn: Yup.string().required('SEO description (EN) is required'),
        seoDescriptionAr: Yup.string().required('SEO description (AR) is required'),

        // SEO Keywords
        seoKeywordsEn: Yup.string().required('SEO keywords (EN) are required'),
        seoKeywordsAr: Yup.string().required('SEO keywords (AR) are required'),

        // OG Title and Descriptions
        ogTitleEn: Yup.string().required('OG title (EN) is required'),
        ogTitleAr: Yup.string().required('OG title (AR) is required'),
        ogDescriptionEn: Yup.string().required('OG description (EN) is required'),
        ogDescriptionAr: Yup.string().required('OG description (AR) is required'),

        // OG Image and URL
        ogImage: Yup.mixed().required('ogImage is required'),
        ogUrl: Yup.string().url('Must be a valid URL').required('OG URL is required'),

        // Twitter Metadata
        twitterCard: Yup.string().required('Twitter card type is required'),
        twitterTitleEn: Yup.string().required('Twitter title (EN) is required'),
        twitterTitleAr: Yup.string().required('Twitter title (AR) is required'),
        twitterDescriptionEn: Yup.string().required('Twitter description (EN) is required'),
        twitterDescriptionAr: Yup.string().required('Twitter description (AR) is required'),
        twitterImage: Yup.mixed().required('twitterImage is required'),
    })

    let formik = useFormik({
        initialValues: {
            nameEn: '',
            nameAr: '',
            couponDescriptionEn: '',
            couponDescriptionAr: '',
            statusEn: '',
            statusAr: '',
            expire: '',
            qrCode: '',
            code: '',
            link: '',
            categoryIds: [],
            countryIds: [],
            brandIds: [],
            seoDescriptionEn: '',
            seoDescriptionAr: '',
            seoKeywordsEn: '',
            seoKeywordsAr: '',
            ogTitleEn: '',
            ogTitleAr: '',
            ogDescriptionEn: '',
            ogDescriptionAr: '',
            ogImage: null,
            ogUrl: '',
            twitterCard: '',
            twitterTitleEn: '',
            twitterTitleAr: '',
            twitterDescriptionEn: '',
            twitterDescriptionAr: '',
            twitterImage: null,

        }, validationSchema: validateSchemaAdd,
        onSubmit: submitAddCoupons

    })


    // update coupon
    const [updateCouponData, setUpdateCouponData] = useState(null);
    const [updatingCouponId, setUpdatingCouponId] = useState("");

    const [ogImageUpdate, setOgImageUpdate] = useState(null); // Use null instead of empty string for files
    const [twitterImageUpdate, setTwitterImageUpdate] = useState(null); // Use null instead of empty string for files

    const [ogImageUpdatePervious, setOgImageUpdatePervious] = useState(null);
    const [twitterImageUpdatePervious, setTwitterImageUpdatePervious] = useState(null);


    const handleOgImageUpdate = (e) => {
        const file = e.target.files[0];
        setOgImageUpdate(file);
    };

    const handleTwitterImageUpdate = (e) => {
        const file = e.target.files[0];
        setTwitterImageUpdate(file);
    };


    // Function to handle update button click
    const handleUpdateClick = (coupon) => {
        if (!coupon.id || typeof coupon.id !== "string") {
            console.error("Invalid coupon ID. Ensure it's a non-empty string.");
            return;
        }

        setUpdateCouponData(coupon);
        setUpdatingCouponId(coupon.id);

        setOgImageUpdatePervious(coupon.ogImage || ""); // Provide default empty string
        setTwitterImageUpdatePervious(coupon.twitterImage || "");

        setSelectedCategories(coupon.categories?.map(category => category.id) || []);
        setSelectedCountries(coupon.countries?.map(country => country.id) || []);
        setSelectedBrands(coupon.brands?.map(brand => brand.id) || []);

        // Set Formik values individually
        formikUpdate.setFieldValue("nameEn", coupon.name?.en || '');
        formikUpdate.setFieldValue("nameAr", coupon.name?.ar || '');
        formikUpdate.setFieldValue("couponDescriptionEn", coupon.description?.en || '');
        formikUpdate.setFieldValue("couponDescriptionAr", coupon.description?.ar || '');
        formikUpdate.setFieldValue("categoryIds", selectedCategories);
        formikUpdate.setFieldValue("countryIds", selectedCountries);
        formikUpdate.setFieldValue("brandIds", selectedBrands);
        formikUpdate.setFieldValue("link", coupon.link || '');
        formikUpdate.setFieldValue("code", coupon.code || '');
        formikUpdate.setFieldValue("expire", coupon.expire ? new Date(coupon.expire).toISOString().split('T')[0] : '');
        formikUpdate.setFieldValue("amount", coupon.amount || '');
        formikUpdate.setFieldValue("statusEn", coupon.status?.en || '');
        formikUpdate.setFieldValue("statusAr", coupon.status?.ar || '');
        formikUpdate.setFieldValue("qrCode", coupon.qrCode || '');
        formikUpdate.setFieldValue("seoDescriptionEn", coupon.seoDescription?.en || '');
        formikUpdate.setFieldValue("seoDescriptionAr", coupon.seoDescription?.ar || '');
        formikUpdate.setFieldValue("seoKeywordsEn", coupon.seoKeywords?.en || '');
        formikUpdate.setFieldValue("seoKeywordsAr", coupon.seoKeywords?.ar || '');
        formikUpdate.setFieldValue("ogTitleEn", coupon.ogTitle?.en || '');
        formikUpdate.setFieldValue("ogTitleAr", coupon.ogTitle?.ar || '');
        formikUpdate.setFieldValue("ogDescriptionEn", coupon.ogDescription?.en || '');
        formikUpdate.setFieldValue("ogDescriptionAr", coupon.ogDescription?.ar || '');
        formikUpdate.setFieldValue("ogUrl", coupon.ogUrl || '');
        formikUpdate.setFieldValue("twitterCard", coupon.twitterCard || '');
        formikUpdate.setFieldValue("twitterTitleEn", coupon.twitterTitle?.en || '');
        formikUpdate.setFieldValue("twitterTitleAr", coupon.twitterTitle?.ar || '');
        formikUpdate.setFieldValue("twitterDescriptionEn", coupon.twitterDescription?.en || '');
        formikUpdate.setFieldValue("twitterDescriptionAr", coupon.twitterDescription?.ar || '');

        toggleModalUpdate();
    };



    async function submitUpdateCoupons(values) {
        try {
            const formData = new FormData();
            formData.append('name[en]', values.nameEn || updateCouponData.name.en);
            formData.append('name[ar]', values.nameAr || updateCouponData.name.ar);
            formData.append('description[en]', values.couponDescriptionEn || updateCouponData.description.en);
            formData.append('description[ar]', values.couponDescriptionAr || updateCouponData.description.ar);
            formData.append('status[en]', values.couponStatusEn || updateCouponData.status.en);
            formData.append('status[ar]', values.couponStatusAr || updateCouponData.status.ar);

            if (values.code !== "") {
                formData.append('code', values.code || updateCouponData.code || "");
            }

            formData.append('amount', values.amount || updateCouponData.amount);
            formData.append('link', values.link || updateCouponData.link);
            // formData.append('expire', values.expire || updateCouponData.expire);
            formData.append('qrCode', values.qrCode || updateCouponData.link);
            formData.append('seoDescription[en]', values.seoDescriptionEn || '');
            formData.append('seoDescription[ar]', values.seoDescriptionAr || '');
            formData.append('seoKeywords[en]', values.seoKeywordsEn || '');
            formData.append('seoKeywords[ar]', values.seoKeywordsAr || '');
            formData.append('ogTitle[en]', values.ogTitleEn || '');
            formData.append('ogTitle[ar]', values.ogTitleAr || '');
            formData.append('ogDescription[en]', values.ogDescriptionEn || '');
            formData.append('ogDescription[ar]', values.ogDescriptionAr || '');
            formData.append('ogUrl', values.ogUrl || '');
            formData.append('twitterCard', values.twitterCard || '');
            formData.append('twitterTitle[en]', values.twitterTitleEn || '');
            formData.append('twitterTitle[ar]', values.twitterTitleAr || '');
            formData.append('twitterDescription[en]', values.twitterDescriptionEn || '');
            formData.append('twitterDescription[ar]', values.twitterDescriptionAr || '');

            // ليه دي هنا
            formData.append('id', updatingCouponId || '');

            // Append file fields only if they have been updated


            if (twitterImageUpdate) {
                formData.append('twitterImage', twitterImageUpdate);
            }

            selectedCategories.forEach((categoryId, index) => {
                formData.append(`categoryIds[${index}]`, categoryId);
            });

            selectedCountries.forEach((countryId, index) => {
                formData.append(`countryIds[${index}]`, countryId);
            });

            selectedBrands.forEach((brandId, index) => {
                formData.append(`brandIds[${index}]`, brandId);
            });


            const response = await axios.patch(
                `${process.env.REACT_APP_BACK_END}admin/coupons/${updatingCouponId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );
            await refetch();

            if (response.data.success) {
                formikUpdate.resetForm();
                setOgImageUpdate(null);
                setTwitterImageUpdate(null);
                toggleModalUpdate();
                toast.success('Coupon updated successfully!');
            }
        } catch (error) {
            if (error.response?.data?.message?.includes("must be unique")) {
                setErrorUpdate("Coupon code must be unique. Please try a different code.");
            } else {
                setErrorUpdate(error?.response?.data?.message);
            }
        }
    }

    let validateSchemaUpdate = Yup.object({
        nameEn: Yup.string(),
        nameAr: Yup.string(),
        couponDescriptionEn: Yup.string(),
        couponDescriptionAr: Yup.string(),
        statusEn: Yup.string(),
        statusAr: Yup.string(),
        qrCode: Yup.string(),
        code: Yup.string(),
        amount: Yup.number().min(0),
        link: Yup.string().url('link a valid URL'),
        expire: Yup.date(),
        categoryIds: Yup.array(),
        countryIds: Yup.array(),
        brandIds: Yup.array(),

        // SEO Descriptions
        seoDescriptionEn: Yup.string(),
        seoDescriptionAr: Yup.string(),

        // SEO Keywords
        seoKeywordsEn: Yup.string(),
        seoKeywordsAr: Yup.string(),

        // OG Title and Descriptions
        ogTitleEn: Yup.string(),
        ogTitleAr: Yup.string(),
        ogDescriptionEn: Yup.string(),
        ogDescriptionAr: Yup.string(),

        // OG Image and URL
        ogImage: Yup.mixed().nullable(),
        ogUrl: Yup.string().url('Must be a valid URL'),

        // Twitter Metadata
        twitterCard: Yup.string(),
        twitterTitleEn: Yup.string(),
        twitterTitleAr: Yup.string(),
        twitterDescriptionEn: Yup.string(),
        twitterDescriptionAr: Yup.string(),
        twitterImage: Yup.mixed().nullable()
    })

    // Formik configuration
    const formikUpdate = useFormik({
        initialValues: {
            nameEn: '',
            nameAr: '',
            couponDescriptionEn: '',
            couponDescriptionAr: '',
            statusEn: '',
            statusAr: '',
            qrCode: '',
            code: '',
            amount: '',
            expire: '',
            link: '',
            categoryIds: [],
            countryIds: [],
            brandIds: [],
            seoDescriptionEn: '',
            seoDescriptionAr: '',
            seoKeywordsEn: '',
            seoKeywordsAr: '',
            ogTitleEn: '',
            ogTitleAr: '',
            ogDescriptionEn: '',
            ogDescriptionAr: '',
            ogImage: null,  // File input initialized to null
            ogUrl: '',
            twitterCard: '',
            twitterTitleEn: '',
            twitterTitleAr: '',
            twitterDescriptionEn: '',
            twitterDescriptionAr: '',
            twitterImage: null, // File input initialized to null
        },
        validationSchema: validateSchemaUpdate,
        onSubmit: submitUpdateCoupons,
    });

    // end update coupon


    // delete coupon
    const [deleteCouponId, setDeleteCouponId] = useState(null);

    // Function to handle update button click
    const handleDeleteClick = (coupon) => {
        setDeleteCouponId(coupon?.id); // Set the ID of the coupon being updated
        toggleModelDel(); // Show the update modal
    };

    // Function to submit update coupon
    async function submitDeleteCoupon() {

        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_BACK_END}admin/coupons/${deleteCouponId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            await refetch();
            toggleModelDel()
            toast.success('coupon delete successfully!');
        } catch (error) {
            setError(error?.response?.data?.message)
        }
    }


    return <>

        <Helmet>
            <meta name='description' content='coupons Page' charSet="utf-8" />
            <title>coupons</title>
        </Helmet>

        <div className="p-6 pe-8 overflow-y-auto rounded-t-xl bg-blue-gray-100 dark:bg-gray-900 flex justify-between align-middle">
            <div className="">
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="relative mt-1">
                    <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                        <i className='fa fa-search w-4 h-4 text-gray-500 dark:text-gray-400' aria-hidden="true"></i>
                    </div>
                    <input type="search"
                        id="table-search"
                        className="block pt-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-48 sm:w-56 lg:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search for coupons By Code..."
                        value={searchQuery} // Bind value to searchQuery state
                        onChange={handleInputChange} // Call handleInputChange on input change
                    />
                </div>
            </div>

            <button
                onClick={toggleModal}
                data-modal-target="crud-modal"
                data-modal-toggle="crud-modal"
                type="button"
                className="focus:outline-none text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                Add New
            </button>
        </div>

        <div className="relative overflow-y-auto md:w-full sm:w-screen shadow-md sm:rounded-lg">

            {
                isLoading ? <div className="loading">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#199A8E"
                        ariaLabel="ball-triangle-loading"
                        visible={true}
                    />
                </div> : allCoupons.length === 0 ? <div className='flex justify-center align-middle p-12 text-teal-800'>
                    <h1 className='font-bold'>not found result try again</h1>
                </div> :
                    <table className="text-center overflow-y-auto w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    <div className="flex items-center justify-center cursor-pointer" onClick={ToggleSortName}>
                                        Name
                                        <span className='ms-2'> {sortName ? <i className="w-3 h-0 fa-xl fa-solid fa-caret-up"></i> : <i className="w-3 h-0 fa-xl fa-solid fa-caret-down"></i>}
                                        </span>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    used Count
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    like Count
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    dislike Count
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    expire
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    link
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    categories
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    brands
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    countries
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    seo Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    seo Keywords
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Title
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    og Image
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ogUrl
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterCard
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterTitle
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterDescription
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    twitterImage
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Deleted At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Update
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Recycle Bin
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {allCoupons?.map((coupon, index) => <tr key={index} className="text-center bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {(currentPage - 1) * 10 + (index + 1)}
                                </th>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.name.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.name?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.description.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.description?.ar}
                                    </h6>
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.code}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.amount}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.usedCount}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.likeCount}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.dislikeCount}
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.status.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.status?.ar}
                                    </h6>
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {coupon.expire ? new Date(coupon.expire)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Created yet"}
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <Link to={coupon.link} target='_blank' className="text-blue-500 hover:text-blue-700">{coupon.link}</Link>
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.categories?.map((category, index) => <h6 key={index} className='mb-2'>
                                        {category.name.en} ,
                                    </h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.brands?.map((brand, index) => <h6 key={index} className='mb-2'>
                                        {brand.name.en} ,
                                    </h6>)}
                                </td>
                                <td className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon?.countries?.map((country, index) => <h6 key={index} className='mb-2'>
                                        {country.name.en} ({country.countryCode}) ,
                                    </h6>)}
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.seoDescription.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.seoDescription?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.seoKeywords.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.seoKeywords?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.ogTitle.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.ogTitle?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.ogDescription.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.ogDescription?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 text-center">
                                    <img
                                        onClick={() => handleImageClick(coupon?.ogImage)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={coupon?.ogImage} className='w-14 cursor-pointer h-16' alt={coupon.name.en} />
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <Link to={coupon.ogUrl} target='_blank' className="text-blue-500 hover:text-blue-700">{coupon.ogUrl}</Link>
                                </td>
                                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {coupon.twitterCard}
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.twitterTitle.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.twitterTitle?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 flex-col overflow-y-auto justify-stretch align-middle font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <h6 className='mb-2 text-left'>
                                        <span className='text-teal-700'>EN:</span> {coupon.twitterDescription.en}
                                    </h6>
                                    <h6 className='text-right'>
                                        <span className='text-teal-700'>العربية :</span> {coupon?.twitterDescription?.ar}
                                    </h6>
                                </td>
                                <td className="px-3 py-4 text-center">
                                    <img
                                        onClick={() => handleImageClick(coupon?.twitterImage)}
                                        data-modal-target="popup-modal-image"
                                        data-modal-toggle="popup-modal-image"
                                        src={coupon?.twitterImage} className='w-14 cursor-pointer h-16' alt={coupon.twitterCard} />
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {coupon.createdAt ? new Date(coupon.createdAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Created yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {coupon.updatedAt ? new Date(coupon.updatedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Updated yet"}
                                </td>
                                <td className="px-6 py-4 text-teal-700 font-extrabold whitespace-nowrap dark:text-white">
                                    {coupon.deletedAt ? new Date(coupon.deletedAt)?.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric' // Change '2-digit' to 'numeric' for full year
                                    }) : "Not Deleted yet"}
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModalUpdate}
                                        onClick={() => handleUpdateClick(coupon)}
                                        data-modal-target="crud-modal-update"
                                        data-modal-toggle="crud-modal-update"
                                        type="button"
                                        className="focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:focus:ring-yellow-900">
                                        Update
                                    </button>
                                </td>
                                <td className="px-6 py-4 mx-auto">
                                    <button
                                        // onClick={toggleModelDel}
                                        onClick={() => handleDeleteClick(coupon)}
                                        data-modal-target="popup-modal"
                                        data-modal-toggle="popup-modal"
                                        type="button"
                                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
                                        Delete
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
            }

        </div>


        {/* Pagination Controls */}
        <div className="flex justify-center space-x-2 mt-4">
            {/* Previous Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </button>

            {/* Page Numbers */}
            {[...Array(totalPages)].map((_, i) => (
                <button
                    key={i}
                    className={`px-4 py-2 ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                        } hover:bg-gray-300 rounded`}
                    onClick={() => handlePageChange(i + 1)}
                >
                    {i + 1}
                </button>
            ))}

            {/* Next Button */}
            <button
                className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                Next
            </button>
        </div>


        {/* Main modal add */}
        <div
            id="crud-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create New Coupon
                        </h3>
                        <button
                            onClick={toggleModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formik.handleSubmit} className="p-4 md:p-5">

                        <div className="grid gap-4 mb-4 grid-cols-2">
                            {/* Name En */}
                            <div className="col-span-2">
                                <label htmlFor="nameEn" className="block mb-2 text-sm font-medium text-gray-900"> coupon Name EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="nameEn"
                                    id="nameEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.nameEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Name (English)..."
                                />
                                {formik.errors.nameEn && formik.touched.nameEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.nameEn}</div>}
                            </div>

                            {/* name Ar */}
                            <div className="col-span-2">
                                <label htmlFor="nameAr" className="block mb-2 text-sm font-medium text-gray-900"> coupon Name Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="nameAr"
                                    id="nameAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.nameAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Name (Arabic)..."
                                />
                                {formik.errors.nameAr && formik.touched.nameAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.nameAr}</div>}
                            </div>

                            {/* coupon DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="couponDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">coupon Description En <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="couponDescriptionEn"
                                    id="couponDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.couponDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon description (English) ..."
                                />
                                {formik.errors.couponDescriptionEn && formik.touched.couponDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.couponDescriptionEn}</div>}
                            </div>

                            {/* coupon DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="couponDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">coupon Description Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="couponDescriptionAr"
                                    id="couponDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.couponDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon description (Arabic) ..."
                                />
                                {formik.errors.couponDescriptionAr && formik.touched.couponDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.couponDescriptionAr}</div>}
                            </div>

                            {/* code */}
                            <div className="col-span-2">
                                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 ">coupon Code <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.code}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon code ex:sr25..."
                                />

                                {formik.errors.code && formik.touched.code ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.code}</div> : ''}
                            </div>

                            {/* amount */}
                            <div className="col-span-2">
                                <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 ">Amount <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    min={0}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.amount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Amount Discount..."
                                />

                                {formik.errors.amount && formik.touched.amount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.amount}</div> : ''}
                            </div>

                            {/* expiration date */}
                            <div className="col-span-2">
                                <label htmlFor="expire" className="block mb-2 text-sm font-medium text-gray-900">Expiration Date <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="date"
                                    name="expire"
                                    id="expire"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.expire}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon expiration date..."
                                />
                                {formik.errors.expire && formik.touched.expire && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.expire}</div>}
                            </div>

                            {/* qrCode */}
                            <div className="col-span-2">
                                <label htmlFor="qrCode" className="block mb-2 text-sm font-medium text-gray-900 ">coupon QrCode <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="qrCode"
                                    id="qrCode"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.qrCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon qrCode ex:dqwd..."
                                />

                                {formik.errors.qrCode && formik.touched.qrCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.qrCode}</div> : ''}
                            </div>

                            {/* status En */}
                            <div className="col-span-2">
                                <label htmlFor="statusEn" className="block mb-2 text-sm font-medium text-gray-900"> coupon Status EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="statusEn"
                                    id="statusEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.statusEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Status (English)..."
                                />
                                {formik.errors.statusEn && formik.touched.statusEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.statusEn}</div>}
                            </div>

                            {/* status Ar */}
                            <div className="col-span-2">
                                <label htmlFor="statusAr" className="block mb-2 text-sm font-medium text-gray-900"> coupon Status Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="statusAr"
                                    id="statusAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.statusAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Name (Arabic)..."
                                />
                                {formik.errors.statusAr && formik.touched.statusAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.statusAr}</div>}
                            </div>

                            {/* link */}
                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900">coupon Link <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="url"
                                    name="link"
                                    id="link"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG URL..."
                                />
                                {formik.errors.link && formik.touched.link && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.link}</div>}
                            </div>

                            {/* Brands */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search brands..."
                                    value={searchQueryBrands}
                                    onChange={(e) => setSearchQueryBrands(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingBrands ? (
                                        <div className="loading-spinner">Loading Brands...</div>
                                    ) : brands.length ? (
                                        brands.map((brand, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={brand.id}
                                                    type="checkbox"
                                                    checked={selectedBrands.includes(brand.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedBrands, brand.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={brand.id}>{brand.name?.en || brand.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className='text-red-600 text-sm'>
                                            No brands found, please try again.</span>
                                    )}
                                </div>
                                {!selectedBrands.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one brand.</div>}
                            </div>

                            {/* Categories */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search categories..."
                                    value={searchQueryCategories}
                                    onChange={(e) => setSearchQueryCategories(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingCategories ? (
                                        <div className="loading-spinner">Loading Categories...</div>
                                    ) : categories.length ? (
                                        categories.map((category, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={category.id}
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedCategories, category.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={category.id}>{category.name?.en || category.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className="text-red-600 text-sm">No categories found, please try again.</span>
                                    )}
                                </div>
                                {!selectedCategories.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one category.</div>}
                            </div>

                            {/* Countries */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search countries..."
                                    value={searchQueryCountries}
                                    onChange={(e) => setSearchQueryCountries(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingCountries ? (
                                        <div className="loading-spinner">Loading Countries...</div>
                                    ) : countries.length ? (
                                        countries.map((country, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={country.id}
                                                    type="checkbox"
                                                    checked={selectedCountries.includes(country.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedCountries, country.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={country.id}>{country.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className='text-red-600 text-sm'>No countries found, please try again.</span>
                                    )}
                                </div>
                                {!selectedCountries.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one country.</div>}
                            </div>

                            {/* SEO DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Description En <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoDescriptionEn"
                                    id="seoDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (English) ..."
                                />
                                {formik.errors.seoDescriptionEn && formik.touched.seoDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoDescriptionEn}</div>}
                            </div>

                            {/* SEO DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Description Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoDescriptionAr"
                                    id="seoDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (Arabic) ..."
                                />
                                {formik.errors.seoDescriptionAr && formik.touched.seoDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoDescriptionAr}</div>}
                            </div>

                            {/* SEO KeywordsEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords En <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoKeywordsEn"
                                    id="seoKeywordsEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoKeywordsEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (English)..."
                                />
                                {formik.errors.seoKeywordsEn && formik.touched.seoKeywordsEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoKeywordsEn}</div>}
                            </div>

                            {/* SEO KeywordsAr */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="seoKeywordsAr"
                                    id="seoKeywordsAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.seoKeywordsAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (Arabic)..."
                                />
                                {formik.errors.seoKeywordsAr && formik.touched.seoKeywordsAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.seoKeywordsAr}</div>}
                            </div>

                            {/* OG TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleEn" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="ogTitleEn"
                                    id="ogTitleEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (English)..."
                                />
                                {formik.errors.ogTitleEn && formik.touched.ogTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogTitleEn}</div>}
                            </div>

                            {/* OG TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleAr" className="block mb-2 text-sm font-medium text-gray-900">OG Title Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="ogTitleAr"
                                    id="ogTitleAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogTitleAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (Arabic)..."
                                />
                                {formik.errors.ogTitleAr && formik.touched.ogTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogTitleAr}</div>}
                            </div>

                            {/* OG DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ogDescriptionEn"
                                    id="ogDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (English)..."
                                />
                                {formik.errors.ogDescriptionEn && formik.touched.ogDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogDescriptionEn}</div>}
                            </div>

                            {/* OG DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">OG Description AR <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="ogDescriptionAr"
                                    id="ogDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (Arabic)..."
                                />
                                {formik.errors.ogDescriptionAr && formik.touched.ogDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogDescriptionAr}</div>}
                            </div>

                            {/* OG Image */}
                            <div className="col-span-2">
                                <label htmlFor="ogImage" className="block mb-2 text-sm font-medium text-gray-900">OG Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="ogImage"
                                    name="ogImage"
                                    type="file"
                                    onChange={(e) => {
                                        handelOgImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.ogImage && formik.touched.ogImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogImage}</div>}
                            </div>

                            {/* OG URL */}
                            <div className="col-span-2">
                                <label htmlFor="ogUrl" className="block mb-2 text-sm font-medium text-gray-900">OG URL <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="url"
                                    name="ogUrl"
                                    id="ogUrl"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.ogUrl}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG URL..."
                                />
                                {formik.errors.ogUrl && formik.touched.ogUrl && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.ogUrl}</div>}
                            </div>

                            {/* Twitter Card */}
                            <div className="col-span-2">
                                <label htmlFor="twitterCard" className="block mb-2 text-sm font-medium text-gray-900">Twitter Card <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterCard"
                                    id="twitterCard"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterCard}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter Card type..."
                                />
                                {formik.errors.twitterCard && formik.touched.twitterCard && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterCard}</div>}
                            </div>

                            {/* Twitter TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterTitleEn"
                                    id="twitterTitleEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (English)..."
                                />
                                {formik.errors.twitterTitleEn && formik.touched.twitterTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterTitleEn}</div>}
                            </div>

                            {/* Twitter TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title AR <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    type="text"
                                    name="twitterTitleAr"
                                    id="twitterTitleAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (Arabic)..."
                                />
                                {formik.errors.twitterTitleAr && formik.touched.twitterTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterTitleAr}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description EN <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="twitterDescriptionEn"
                                    id="twitterDescriptionEn"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (English)..."
                                />
                                {formik.errors.twitterDescriptionEn && formik.touched.twitterDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterDescriptionEn}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description Ar <span className='text-red-600 font-extrabold '>*</span></label>
                                <textarea
                                    name="twitterDescriptionAr"
                                    id="twitterDescriptionAr"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.twitterDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (Arabic)..."
                                />
                                {formik.errors.twitterDescriptionAr && formik.touched.twitterDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterDescriptionAr}</div>}
                            </div>

                            {/* Twitter Image */}
                            <div className="col-span-2">
                                <label htmlFor="twitterImage" className="block mb-2 text-sm font-medium text-gray-900">Twitter Image <span className='text-red-600 font-extrabold '>*</span></label>
                                <input
                                    id="twitterImage"
                                    name="twitterImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleTwitterImage(e);
                                        formik.handleChange(e);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formik.errors.twitterImage && formik.touched.twitterImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.twitterImage}</div>}
                            </div>
                        </div>

                        <button
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Add new coupon
                        </button>

                        {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                    </form>

                </div>
            </div>
        </div>


        {/* Main modal update */}
        <div
            id="crud-modal-update"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >

            <div className="relative overflow-auto bg-white p-4 rounded-lg shadow-lg mt-9 w-[80%] xl:w-[45%] max-h-full">

                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">

                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Update coupon
                        </h3>
                        <button
                            onClick={toggleModalUpdate}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            data-modal-toggle="crud-modal"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* Modal body */}
                    <form onSubmit={formikUpdate.handleSubmit} className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            {/* Name En */}
                            <div className="col-span-2">
                                <label htmlFor="nameEn" className="block mb-2 text-sm font-medium text-gray-900"> coupon Name EN</label>
                                <input
                                    type="text"
                                    name="nameEn"
                                    id="nameEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.nameEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Name (English)..."
                                />
                                {formikUpdate.errors.nameEn && formikUpdate.touched.nameEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.nameEn}</div>}
                            </div>

                            {/* name Ar */}
                            <div className="col-span-2">
                                <label htmlFor="nameAr" className="block mb-2 text-sm font-medium text-gray-900"> coupon Name Ar</label>
                                <input
                                    type="text"
                                    name="nameAr"
                                    id="nameAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.nameAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Name (Arabic)..."
                                />
                                {formikUpdate.errors.nameAr && formikUpdate.touched.nameAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.nameAr}</div>}
                            </div>

                            {/* coupon DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="couponDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">coupon Description En</label>
                                <textarea
                                    name="couponDescriptionEn"
                                    id="couponDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.couponDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon description (English) ..."
                                />
                                {formikUpdate.errors.couponDescriptionEn && formikUpdate.touched.couponDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.couponDescriptionEn}</div>}
                            </div>

                            {/* coupon DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="couponDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">coupon Description Ar</label>
                                <textarea
                                    name="couponDescriptionAr"
                                    id="couponDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.couponDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon description (Arabic) ..."
                                />
                                {formikUpdate.errors.couponDescriptionAr && formikUpdate.touched.couponDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.couponDescriptionAr}</div>}
                            </div>

                            {/* coupon Link */}
                            <div className="col-span-2">
                                <label htmlFor="link" className="block mb-2 text-sm font-medium text-gray-900">coupon Link</label>
                                <input
                                    type="url"
                                    name="link"
                                    id="link"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.link}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Link..."
                                />
                                {formikUpdate.errors.link && formikUpdate.touched.link && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.link}</div>}
                            </div>


                            {/* code */}
                            <div className="col-span-2">
                                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 ">coupon Code</label>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.code}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon code ex:sr25..."
                                />

                                {formikUpdate.errors.code && formikUpdate.touched.code ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.code}</div> : ''}
                            </div>

                            {/* amount */}
                            <div className="col-span-2">
                                <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 ">Amount</label>
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    min={0}
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.amount}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon Amount Discount..."
                                />

                                {formikUpdate.errors.amount && formikUpdate.touched.amount ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.amount}</div> : ''}
                            </div>

                            {/* expiration date */}
                            <div className="col-span-2">
                                <label htmlFor="expire" className="block mb-2 text-sm font-medium text-gray-900">Expiration Date</label>
                                <input
                                    type="date"
                                    name="expire"
                                    id="expire"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.expire}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon expiration date..."
                                />
                                {formikUpdate.errors.expire && formikUpdate.touched.expire && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.expire}</div>}
                            </div>

                            {/* qrCode */}
                            <div className="col-span-2">
                                <label htmlFor="qrCode" className="block mb-2 text-sm font-medium text-gray-900 ">coupon QrCode</label>
                                <input
                                    type="text"
                                    name="qrCode"
                                    id="qrCode"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.qrCode}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type coupon qrCode ex:dqwd..."
                                />

                                {formikUpdate.errors.qrCode && formikUpdate.touched.qrCode ? <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.qrCode}</div> : ''}
                            </div>

                            {/* Brands */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search brands..."
                                    value={searchQueryBrands ?? ""}
                                    onChange={(e) => setSearchQueryBrands(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingBrands ? (
                                        <div className="loading-spinner">Loading Brands...</div>
                                    ) : brands.length ? (
                                        brands.map((brand, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={brand.id}
                                                    type="checkbox"
                                                    checked={selectedBrands.includes(brand.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedBrands, brand.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={brand.id}>{brand.name?.en || brand.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className='text-red-600 text-sm'>
                                            No brands found, please try again.</span>
                                    )}
                                </div>
                                {!selectedBrands.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one brand.</div>}
                            </div>

                            {/* Categories */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search categories..."
                                    value={searchQueryCategories ?? ""}
                                    onChange={(e) => setSearchQueryCategories(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingCategories ? (
                                        <div className="loading-spinner">Loading Categories...</div>
                                    ) : categories.length ? (
                                        categories.map((category, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={category.id}
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedCategories, category.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={category.id}>{category.name?.en || category.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className="text-red-600 text-sm">No categories found, please try again.</span>
                                    )}
                                </div>
                                {!selectedCategories.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one category.</div>}
                            </div>

                            {/* Countries */}
                            <div className="col-span-2">
                                <input
                                    type="search"
                                    placeholder="Search countries..."
                                    value={searchQueryCountries ?? ""}
                                    onChange={(e) => setSearchQueryCountries(e.target.value)}
                                    className="block w-full px-4 py-2 mb-4 text-sm text-gray-900 bg-gray-100 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent dark:bg-gray-800 dark:text-white dark:border-gray-600"
                                />
                                <div className="flex flex-wrap gap-4 p-2 bg-gray-50 border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                                    {loadingCountries ? (
                                        <div className="loading-spinner">Loading Countries...</div>
                                    ) : countries.length ? (
                                        countries.map((country, i) => (
                                            <div key={i} className="checkbox-group">
                                                <input
                                                    id={country.id}
                                                    type="checkbox"
                                                    checked={selectedCountries.includes(country.id)}
                                                    onChange={() => handleCheckboxChange(setSelectedCountries, country.id)}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:ring-offset-gray-800"
                                                />
                                                <label className="ml-2 cursor-pointer" htmlFor={country.id}>{country.name}</label>
                                            </div>
                                        ))
                                    ) : (
                                        <span className='text-red-600 text-sm'>No countries found, please try again.</span>
                                    )}
                                </div>
                                {!selectedCountries.length && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">Please select at least one country.</div>}
                            </div>

                            {/* SEO DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Description En</label>
                                <textarea
                                    name="seoDescriptionEn"
                                    id="seoDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (English) ..."
                                />
                                {formikUpdate.errors.seoDescriptionEn && formikUpdate.touched.seoDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoDescriptionEn}</div>}
                            </div>

                            {/* SEO DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="seoDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Description Ar</label>
                                <textarea
                                    name="seoDescriptionAr"
                                    id="seoDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO description (Arabic)..."
                                />
                                {formikUpdate.errors.seoDescriptionAr && formikUpdate.touched.seoDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoDescriptionAr}</div>}
                            </div>

                            {/* SEO Keywords en */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsEn" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords En</label>
                                <textarea
                                    name="seoKeywordsEn"
                                    id="seoKeywordsEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoKeywordsEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (English)..."
                                />
                                {formikUpdate.errors.seoKeywordsEn && formikUpdate.touched.seoKeywordsEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoKeywordsEn}</div>}
                            </div>

                            {/* SEO Keywords ar */}
                            <div className="col-span-2">
                                <label htmlFor="seoKeywordsAr" className="block mb-2 text-sm font-medium text-gray-900">SEO Keywords Ar</label>
                                <textarea
                                    name="seoKeywordsAr"
                                    id="seoKeywordsAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.seoKeywordsAr}
                                    className=" text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type SEO keywords (Arabic)..."
                                />
                                {formikUpdate.errors.seoKeywordsAr && formikUpdate.touched.seoKeywordsAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.seoKeywordsAr}</div>}
                            </div>

                            {/* OG TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleEn" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN</label>
                                <input
                                    type="text"
                                    name="ogTitleEn"
                                    id="ogTitleEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (English)..."
                                />
                                {formikUpdate.errors.ogTitleEn && formikUpdate.touched.ogTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogTitleEn}</div>}
                            </div>

                            {/* OG TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogTitleAr" className="block mb-2 text-sm font-medium text-gray-900">OG Title EN</label>
                                <input
                                    type="text"
                                    name="ogTitleAr"
                                    id="ogTitleAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG title (Arabic)..."
                                />
                                {formikUpdate.errors.ogTitleAr && formikUpdate.touched.ogTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogTitleAr}</div>}
                            </div>

                            {/* OG DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN</label>
                                <textarea
                                    name="ogDescriptionEn"
                                    id="ogDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (English)..."
                                />
                                {formikUpdate.errors.ogDescriptionEn && formikUpdate.touched.ogDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogDescriptionEn}</div>}
                            </div>

                            {/* OG DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="ogDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">OG Description EN</label>
                                <textarea
                                    name="ogDescriptionAr"
                                    id="ogDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG description (Arabic)..."
                                />
                                {formikUpdate.errors.ogDescriptionAr && formikUpdate.touched.ogDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogDescriptionAr}</div>}
                            </div>

                            {/* OG Image */}
                            <div className="col-span-2">
                                <label htmlFor="ogImage" className="block mb-2 text-sm font-medium text-gray-900">OG Image</label>
                                <input
                                    id="ogImage"
                                    name="ogImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleOgImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.ogImage && formikUpdate.touched.ogImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogImage}</div>}
                            </div>

                            <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                                {ogImageUpdatePervious && (
                                    <div>
                                        <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                        <img src={ogImageUpdatePervious} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                                {ogImageUpdate && (
                                    <div>
                                        <label className="mb-4 text-sm font-medium text-gray-900">New Image Pervious</label>
                                        <img src={URL.createObjectURL(ogImageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                    </div>
                                )}
                            </div>


                            {/* OG URL */}
                            <div className="col-span-2">
                                <label htmlFor="ogUrl" className="block mb-2 text-sm font-medium text-gray-900">OG URL</label>
                                <input
                                    type="url"
                                    name="ogUrl"
                                    id="ogUrl"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.ogUrl}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type OG URL..."
                                />
                                {formikUpdate.errors.ogUrl && formikUpdate.touched.ogUrl && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.ogUrl}</div>}
                            </div>

                            {/* Twitter Card */}
                            <div className="col-span-2">
                                <label htmlFor="twitterCard" className="block mb-2 text-sm font-medium text-gray-900">Twitter Card</label>
                                <input
                                    type="text"
                                    name="twitterCard"
                                    id="twitterCard"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterCard}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter Card type..."
                                />
                                {formikUpdate.errors.twitterCard && formikUpdate.touched.twitterCard && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterCard}</div>}
                            </div>

                            {/* Twitter TitleEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN</label>
                                <input
                                    type="text"
                                    name="twitterTitleEn"
                                    id="twitterTitleEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterTitleEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (English)..."
                                />
                                {formikUpdate.errors.twitterTitleEn && formikUpdate.touched.twitterTitleEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterTitleEn}</div>}
                            </div>

                            {/* Twitter TitleAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterTitleAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Title EN</label>
                                <input
                                    type="text"
                                    name="twitterTitleAr"
                                    id="twitterTitleAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterTitleAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter title (Arabic)..."
                                />
                                {formikUpdate.errors.twitterTitleAr && formikUpdate.touched.twitterTitleAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterTitleAr}</div>}
                            </div>

                            {/* Twitter DescriptionEn */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionEn" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description EN</label>
                                <textarea
                                    name="twitterDescriptionEn"
                                    id="twitterDescriptionEn"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterDescriptionEn}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (English)..."
                                />
                                {formikUpdate.errors.twitterDescriptionEn && formikUpdate.touched.twitterDescriptionEn && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterDescriptionEn}</div>}
                            </div>

                            {/* Twitter DescriptionAr */}
                            <div className="col-span-2">
                                <label htmlFor="twitterDescriptionAr" className="block mb-2 text-sm font-medium text-gray-900">Twitter Description Ar</label>
                                <textarea
                                    name="twitterDescriptionAr"
                                    id="twitterDescriptionAr"
                                    onBlur={formikUpdate.handleBlur}
                                    onChange={formikUpdate.handleChange}
                                    value={formikUpdate.values.twitterDescriptionAr}
                                    className="text-right bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                    placeholder="Type Twitter description (Arabic)..."
                                />
                                {formikUpdate.errors.twitterDescriptionAr && formikUpdate.touched.twitterDescriptionAr && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterDescriptionAr}</div>}
                            </div>

                            {/* Twitter Image */}
                            <div className="col-span-2">
                                <label htmlFor="twitterImage" className="block mb-2 text-sm font-medium text-gray-900">Twitter Image</label>
                                <input
                                    id="twitterImage"
                                    name="twitterImage"
                                    type="file"
                                    onChange={(e) => {
                                        handleTwitterImageUpdate(e);
                                        formikUpdate.handleChange(e);
                                    }}
                                    onBlur={formikUpdate.handleBlur}
                                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                                />
                                {formikUpdate.errors.twitterImage && formikUpdate.touched.twitterImage && <div className="py-2 mt-2 w-full px-2 bg-red-400 text-red-100 rounded-md">{formikUpdate.errors.twitterImage}</div>}
                            </div>
                        </div>

                        <div className="flex justify-center col-span-2 gap-x-6 text-center py-2 bg-gray-200">
                            {twitterImageUpdatePervious && (
                                <div>
                                    <label className="mb-2 text-sm font-medium text-gray-900">Current Image</label>
                                    <img src={twitterImageUpdatePervious} alt="Previous" className="h-24 w-24 mt-1 object-cover" />
                                </div>
                            )}
                            {twitterImageUpdate && (
                                <div>
                                    <label className="mb-4 text-sm font-medium text-gray-900">New Image Pervious</label>
                                    <img src={URL.createObjectURL(twitterImageUpdate)} alt="New" className="h-24 w-24 mt-1 object-cover" />
                                </div>
                            )}
                        </div>


                        <button
                            // disabled={!(formikUpdate.isValid && formikUpdate.dirty)}
                            type='submit'
                            className=" mt-4 text-white inline-flex items-center bg-yellow-700 hover:bg-yellow-800 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Update Coupon
                        </button>

                        {errorUpdate ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{errorUpdate}</div> : ''}
                    </form>
                </div>
            </div>
        </div>



        {/* delete model */}
        <div
            id="popup-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-4 w-[80%] xl:w-[45%] max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal"
                        onClick={toggleModelDel}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg
                            className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        <h3 className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Delete this Coupon ?
                        </h3>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                            onClick={submitDeleteCoupon}
                        >
                            Yes, I'm sure
                        </button>
                        <button
                            data-modal-hide="popup-modal"
                            type="button"
                            onClick={toggleModelDel}
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>


        {/* image model */}
        <div
            id="popup-modal-image"
            tabIndex="-1"
            aria-hidden="true"
            className="hidden overflow-auto fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
            <div className="relative p-6 w-[80%] xl:w-[50%] h-[95vh] xl:h-[100vh]">
                <div className="relative bg-[#ffffffd1] rounded-lg shadow dark:bg-gray-700">
                    <button
                        type="button"
                        className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="popup-modal-image"
                        onClick={toggleModelImg}
                    >
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>

                    <div className="p-1 md:p-2 text-center">

                        <img src={imageSrc || ""} alt="" className='w-full h-full p-3 py-14' />

                        <button
                            data-modal-hide="popup-modal-image"
                            type="button"
                            onClick={toggleModelImg}
                            className="absolute bottom-0.5 mb-1 right-2 py-2 px-4 ms-3 text-md font-medium text-white focus:outline-none bg-teal-500 hover:bg-teal-700 focus:bg-teal-700 rounded-xl border border-gray-200 focus:z-10 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            close
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </>
}
