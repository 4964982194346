import React from 'react'
import notFound from '../../Assets/images/error.svg';
import { Helmet } from 'react-helmet';

export default function Notfound() {
    return <>

        <Helmet>
            <meta name='description' content='Not Found Page' charSet="utf-8" />
            <title>Not Found</title>
        </Helmet>

        <div className="mx-auto w-fit h-fit flex-row justify-center align-middle">
            <img src={notFound} alt="not found photo" className='w-100' />

            <h2 className='text-center mt-10 font-bold text-green-700'>Error You can return a dashboard use side Bar</h2>
        </div>
    </>
}
