import React, { useContext, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FallingLines } from "react-loader-spinner";
import { UserContext } from "../../Context/UserContext.js";
import { Helmet } from "react-helmet";

export default function LoginWithEmail() {
    const { setRefresh_token, setUserData } = useContext(UserContext);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [logging, setLogging] = useState(false);

    // Toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const submitLogin = useCallback(async (values) => {
        setLogging(true);
        values.loginMethod = "email";
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACK_END}auth/login`, values);
            const { success, data } = response.data;

            if (!success || data.user.role !== "Admin") {
                throw new Error("You are not allowed to login to the dashboard.");
            }

            localStorage.setItem("refresh_token", data.token.refresh_token);
            setRefresh_token(data.token.refresh_token);
            setUserData(data.token.refresh_token);
            navigate("/");
        } catch (err) {
            setError(err.response?.data.message || err.message);
        } finally {
            setLogging(false);
        }
    }, [navigate, setRefresh_token, setUserData]);

    const formik = useFormik({
        initialValues: { identifier: "", password: "" },
        validationSchema: Yup.object({
            identifier: Yup.string().email("Invalid email").required("Email is required"),
            password: Yup.string().min(6, "Min 6 characters").required("Password is required"),
        }),
        onSubmit: submitLogin,
    });

    return (
        <>
            <Helmet>
                <meta name="description" content="Login Page" charSet="utf-8" />
                <title>Login With Email</title>
            </Helmet>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6" onSubmit={formik.handleSubmit}>
                    {/* Email Field */}
                    <div>
                        <label
                            htmlFor="identifier"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.identifier}
                                id="identifier"
                                name="identifier"
                                type="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {formik.touched.identifier && formik.errors.identifier && (
                                <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">
                                    {formik.errors.identifier}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Password Field */}
                    <div>
                        <div className="flex items-center justify-between">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Password
                            </label>
                            <Link
                                to="/forgetPassword"
                                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                                Forgot password?
                            </Link>
                        </div>
                        <div className="mt-2 relative">
                            <input
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                autoComplete="current-password"
                                className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            <i
                                className={`absolute right-2 top-2.5 ${showPassword ? "fa fa-eye" : "fa fa-eye-slash"} cursor-pointer`}
                                onClick={togglePasswordVisibility}
                            ></i>
                            {formik.touched.password && formik.errors.password && (
                                <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">
                                    {formik.errors.password}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Loading Spinner */}
                    {logging ? (
                        <button type="button" className="flex w-full justify-center rounded-md">
                            <FallingLines width="50" radius="9" color="#3b35b4" ariaLabel="loading" />
                        </button>
                    ) : (
                        <button
                            type="submit"
                            disabled={!(formik.isValid && formik.dirty)}
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 disabled:bg-indigo-400 disabled:cursor-default"
                        >
                            Sign in
                        </button>
                    )}

                    {/* Error Message */}
                    {error && (
                        <div className="py-3 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">
                            {error}
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
