import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logo from '../../Assets/images/couponesta.jpg'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FallingLines } from 'react-loader-spinner'
import { UserContext } from '../../Context/UserContext.js';
import { Helmet } from 'react-helmet';
import { jwtDecode } from "jwt-decode";
import toast from 'react-hot-toast';

export default function NewPassword() {

    let { email } = useParams()

    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)


    async function submitNewPassword(value) {

        try {

            setLogging(true)

            let { data } = await axios.post(`${process.env.REACT_APP_BACK_END}auth/forgetPasswordEmail`, {
                email: email,
                password: value.password
            })
                .catch((err) => {
                    setError(err.response.data.message)
                    setLogging(false)
                })

            if (data.message === 'success') {
                setLogging(false)

                toast.success("Changed password successfully")

                navigate('/login/loginWithEmail')
            }

        } catch (error) {

        }

    }

    let validateSchema = Yup.object({
        password: Yup.string().min(6, 'Password must with character and minLength 6').required('password is required'),
    })

    let formik = useFormik({
        initialValues: {
            password: '',
        }, validationSchema: validateSchema,
        onSubmit: submitNewPassword
    })


    // to show text whe type password
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return <>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">


            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <Link
                    to={'/'}
                    className='cursor-pointer'
                >
                    <img
                        className="mx-auto h-20 rounded-xl  w-auto"
                        src={logo}
                        alt="Your Company"
                    />
                </Link>

                <h1 className='text-center my-2 md:pt-10 mb-5 md:text-3xl text-2xl font-bold text-teal-600'>Enter New Password</h1>

            </div>


            <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">

                <form className="space-y-6" onSubmit={formik.handleSubmit}>

                    <div className="mt-4 relative">
                        <i
                            className={`absolute right-2 top-2.5 ${showPassword ? "fa-solid fa-eye text-gray-700 cursor-pointer"
                                : "fa-solid fa-eye-slash cursor-pointer"}`}
                            onClick={togglePasswordVisibility}
                        ></i>

                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.errors.password && formik.touched.password ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.password}</div> : ''}

                    </div>

                    {logging ? <button type='button' className='flex w-full justify-center rounded-3xl'>

                        <FallingLines
                            width="50"
                            radius="9"
                            color="#3b35b4"
                            visible={true}
                            ariaLabel="falling-circles-loading"
                        />
                    </button> :
                        <div>
                            <button

                                // disabled
                                disabled={!(formik.isValid && formik.dirty)}
                                type='submit'
                                className="hover:translate-y-1 border-[1px] border-gray-300 transition-all ease-out hover:shadow-[0_1.5px_0px_rgb(0,0,0)] flex w-full justify-center cursor-pointer rounded-3xl bg-indigo-600 p-3 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Confirm Change New Password
                            </button>
                        </div>
                    }

                    {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-3xl text-center mx-auto">{error}</div> : ''}



                </form>

            </div>




        </div>
    </>
}
