import axios from 'axios';
import React from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

export default function AllCount() {

    async function getAllCount() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/dashboard/counts`);

            if (response?.data?.success) {
                return response?.data?.data;
            }


        } catch (error) {
        }
    }

    const { data, isLoading, isError } = useQuery('AllCount1', getAllCount, {
        cacheTime: 9000000
    });

    if (isLoading) return <div className="loading">
        <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#199A8E"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{}}
            wrapperClassNameclassName=""
            visible={true}
        /></div>

    if (isError) return <p>Error fetching data</p>;

    // Assuming the data array is ordered as you provided:
    const userCount = data ? data?.users : 0;
    const couponCount = data ? data?.coupon : 0;
    const categoryCount = data ? data?.categories : 0;
    const brandCount = data ? data?.brands : 0;
    const adsCount = data ? data?.ads : 0;
    const countryCount = data ? data?.locations : 0;
    const notificationCount = data ? data?.notifications : 0;

    return (
        <div className="container-fluid px-2 py-1.5 h-full w-full">
            <h1 className='text-center text-teal-500 font-extrabold text-xl xl:text-3xl mb-4'>All Data Count in System</h1>

            <div className="grid place-items-center grid-cols-2 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-x-2 gap-y-3">

                <Link to={'/user'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Users</p>
                        <i className="fa-solid fa-users text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{userCount}</h4>
                </Link>

                <Link to={'/country'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Countries</p>
                        <i className="fa-solid fa-globe text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{countryCount}</h4>
                </Link>

                <Link to={'/coupon'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Coupons</p>
                        <i className="fa-solid fa-ticket text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{couponCount}</h4>
                </Link>

                <Link to={'/category'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Categories</p>
                        <i className="fa-solid fa-layer-group  text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{categoryCount}</h4>
                </Link>

                <Link to={'/brand'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Brands</p>
                        <i className="fa-brands fa-shopify text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{brandCount}</h4>
                </Link>

                <Link to={'/ads'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Ads</p>
                        <i className="fa-solid fa-ad text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{adsCount}</h4>
                </Link>

                <Link to={'/notification'} className='w-full rounded-2xl p-2 border-2 border-teal-500 hover:border-teal-700 hover:border-4 border-double'>
                    <div className="flex justify-between items-center mt-2 gap-x-1">
                        <p className='font-serif font-bold xl:text-nowrap'>Total Notifications</p>
                        <i className="fa-solid fa-bell text-xl xl:text-2xl text-[#3371EB]"></i>
                    </div>
                    <h4 className='mt-2 text-center font-extrabold text-green-700 text-2xl'>{notificationCount}</h4>
                </Link>

            </div>
        </div>
    );
}
