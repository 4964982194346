import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner'


export default function ForgetPasswordEmail() {

    let navigate = useNavigate()
    const [error, setError] = useState(null)
    const [logging, setLogging] = useState(false)

    async function submitForgetPassword(value) {
        setLogging(true)

        let { data } = await axios.post(`${process.env.REACT_APP_BACK_END}auth/forgot-password`, value)
            .catch((err) => {
                setError(err.response.data.message)
                setLogging(false)
            })

        if (data.message === "success") {
            setLogging(false)
            navigate(`/confirmationEmail/${value.email}`)
        }
    }

    let validateSchema = Yup.object({
        email: Yup.string().email('email is invalid').required('email is required'),
    })

    let formik = useFormik({
        initialValues: {
            email: '',
        }, validationSchema: validateSchema,
        onSubmit: submitForgetPassword
    })

    return <>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">

            <form className="space-y-6" onSubmit={formik.handleSubmit}>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 cursor-pointer">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            id='email'
                            name='email'
                            type='email'
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 font-semibold text-gray-900 px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        {formik.errors.email && formik.touched.email ? <div className="py-3 mt-1.5 px-2 bg-red-400 text-red-100 rounded-md">{formik.errors.email}</div> : ''}

                    </div>
                </div>

                {logging ? <button type='button' className='flex w-full justify-center rounded-md'>

                    <FallingLines
                        width="50"
                        radius="9"
                        color="#3b35b4"
                        visible={true}
                        ariaLabel="falling-circles-loading"
                    />
                </button> :
                    <div>
                        <button

                            // disabled
                            disabled={!(formik.isValid && formik.dirty)}
                            type='submit'
                            className="flex w-full justify-center cursor-pointer rounded-md bg-indigo-600 px-3 py-1.5 text-md font-semibold leading-6 disabled:cursor-default disabled:bg-indigo-400 disabled:text-gray-200 text-white shadow-sm active:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Continue
                        </button>
                    </div>
                }

                {error ? <div className="py-3 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}


            </form>

        </div>
    </>

}
