import axios from 'axios';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

// Custom hook for debounced values
function useDebouncedValue(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay);
        return () => clearTimeout(handler); // Cleanup timeout
    }, [value, delay]);

    return debouncedValue;
}

export default function Notification() {
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [searchQueryUsers, setSearchQueryUsers] = useState('');

    const [topics, setTopics] = useState([]);
    const [searchQueryTopics, setSearchQueryTopics] = useState('');

    const [totalPages, setTotalPages] = useState(1); // Total available pages
    const [currentPage, setCurrentPage] = useState(1); // Current page


    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage); // Change current page
    };

    // Debounced search query
    const debouncedSearchQueryTopics = useDebouncedValue(searchQueryTopics, 300);
    const debouncedSearchQueryUsers = useDebouncedValue(searchQueryUsers, 300);

    // Fetch topics from API
    const fetchTopics = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/notifications/topics`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('refresh_token')}` },
            });
            setTopics(response.data.data || []); // Ensure safe handling of `data`
        } catch (error) {
            // console.error('Error fetching topics:', error);
        }
    }, []);

    useEffect(() => {
        fetchTopics();
    }, [fetchTopics]);

    // Fetch token users from API
    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/notifications/users?page=${currentPage}&limit=30`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('refresh_token')}` },
            });

            setTotalPages(response?.data?.totalPages);

            setUsers(response.data.data || []); // Ensure safe handling of `data`
        } catch (error) {
            // console.error('Error fetching topics:', error);
        }
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);


    // Form submission for push notification
    const submitNotification = async (values) => {

        let payload;

        if (values.action === 'topic') {
            // Single topic payload
            payload = {
                action: 'topic',
                data: {
                    topic: values.topic, // Single topic
                    notificationData: {
                        title: {
                            en: values.notificationTitleEn,
                            ar: values.notificationTitleAr,
                        },
                        body: {
                            en: values.notificationBodyEn,
                            ar: values.notificationBodyAr,
                        },
                    },
                },
            };
        } else if (values.action === 'topics') {
            // Multiple topics payload
            payload = {
                action: 'topics',
                data: {
                    topics: values.topics, // Multiple topics
                    notificationData: {
                        title: {
                            en: values.notificationTitleEn,
                            ar: values.notificationTitleAr,
                        },
                        body: {
                            en: values.notificationBodyEn,
                            ar: values.notificationBodyAr,
                        },
                    },
                },
            };
        } else if (values.action === 'groupOfUsers') {
            // Single topic payload
            payload = {
                action: 'groupOfUsers',
                data: {
                    groupOfUsers: values.groupOfUsers,
                    notificationData: {
                        title: {
                            en: values.notificationTitleEn,
                            ar: values.notificationTitleAr,
                        },
                        body: {
                            en: values.notificationBodyEn,
                            ar: values.notificationBodyAr,
                        },
                    },
                },
            };
        } else if (values.action === 'all') {
            // Single topic payload
            payload = {
                action: 'all',
                data: {
                    notificationData: {
                        title: {
                            en: values.notificationTitleEn,
                            ar: values.notificationTitleAr,
                        },
                        body: {
                            en: values.notificationBodyEn,
                            ar: values.notificationBodyAr,
                        },
                    },
                },
            };
        }


        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_END}admin/notifications/push-notification`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('refresh_token')}`,
                    },
                }
            );

            if (response.status === 201) {
                formik.resetForm();
                toast.success('Notification sent successfully!');
            } else {
                setError("can't be add this notification");
                toast.error("can't be add this notification");
            }
        } catch (error) {
            setError("can't be add this notification");
        }

    };

    const validateSchema = Yup.object({
        notificationTitleEn: Yup.string().required('Notification title (EN) is required'),
        notificationBodyEn: Yup.string().required('Notification body (EN) is required'),
        notificationTitleAr: Yup.string().required('Notification title (AR) is required'),
        notificationBodyAr: Yup.string().required('Notification body (AR) is required'),
        action: Yup.string().required("Action is required"),

    });

    const formik = useFormik({
        initialValues: {
            action: '',
            notificationTitleEn: '',
            notificationBodyEn: '',
            notificationTitleAr: '',
            notificationBodyAr: '',
            topics: [],
            topic: '',
            groupOfUsers: [],
            groupOfDevices: []
        },
        validationSchema: validateSchema,
        onSubmit: submitNotification,
    });

    // Filter topics based on search input
    const filteredTopics = useMemo(() => {
        return topics.filter((topic) =>
            topic?.toLowerCase()?.includes(debouncedSearchQueryTopics?.toLowerCase())
        );
    }, [topics, debouncedSearchQueryTopics]);

    // Handle individual topic selection
    const handleSingleTopicSelection = (topic) => {
        formik.setFieldValue('topic', topic); // Set single topic
    };

    const handleMultipleTopicSelection = (topic) => {
        const updatedTopics = formik.values.topics.includes(topic)
            ? formik.values.topics.filter((t) => t !== topic) // Remove if already selected
            : [...formik.values.topics, topic]; // Add if not selected

        formik.setFieldValue('topics', updatedTopics); // Update multiple topics
    };


    /** ######################################################################## */
    // Filter token users based on search input
    const filteredUsers = useMemo(() => {
        return users.filter((user) =>
            user?.fullName?.toLowerCase()?.includes(debouncedSearchQueryUsers?.toLowerCase())
        );
    }, [users, debouncedSearchQueryUsers]);


    const handleMultipleUserSelection = (user) => {

        const updatedUsersGroup = formik.values.groupOfUsers.includes(user)
            ? formik.values.groupOfUsers.filter((t) => t !== user) // Remove if already selected
            : [...formik.values.groupOfUsers, user]; // Add if not selected

        formik.setFieldValue('groupOfUsers', updatedUsersGroup); // Update multiple groupOfUsers
    };

    return (
        <>
            <Helmet>
                <meta name='description' content='Notification Page' charSet="utf-8" />
                <title>Notification</title>
            </Helmet>

            <div className="container mx-auto bg-gradient-to-br from-indigo-700 via-green-300 to-blue-900 p-6 rounded-3xl shadow-xl mt-3">
                <h3 className="text-xl font-semibold text-white mb-5">Create New Notification</h3>
                <form onSubmit={formik.handleSubmit} className="p-6 bg-white rounded-3xl shadow-md">
                    <div className="grid gap-6 mb-6 grid-cols-1 md:grid-cols-2">

                        {/* title  */}
                        <div className="col-span-2">
                            <label htmlFor="notificationTitleEn" className="block mb-2 text-sm font-medium text-gray-700">Notification Title (EN) <span className='text-red-600 font-extrabold '>*</span></label>
                            <input
                                type="text"
                                name="notificationTitleEn"
                                id="notificationTitleEn"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.notificationTitleEn}
                                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Notification title (EN)"
                            />
                            {formik.errors.notificationTitleEn && formik.touched.notificationTitleEn && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.notificationTitleEn}</div>
                            )}
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="notificationTitleAr" className="block mb-2 text-sm font-medium text-gray-700">Notification Title (AR) <span className='text-red-600 font-extrabold '>*</span></label>
                            <input
                                type="text"
                                name="notificationTitleAr"
                                id="notificationTitleAr"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.notificationTitleAr}
                                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Notification title (AR)"
                            />
                            {formik.errors.notificationTitleAr && formik.touched.notificationTitleAr && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.notificationTitleAr}</div>
                            )}
                        </div>
                        {/* end title  */}


                        {/* body */}
                        <div className="col-span-2">
                            <label htmlFor="notificationBodyEn" className="block mb-2 text-sm font-medium text-gray-700">Notification Body (EN) <span className='text-red-600 font-extrabold '>*</span></label>
                            <textarea
                                name="notificationBodyEn"
                                id="notificationBodyEn"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.notificationBodyEn}
                                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Notification body (EN)"
                            />
                            {formik.errors.notificationBodyEn && formik.touched.notificationBodyEn && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.notificationBodyEn}</div>
                            )}
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="notificationBodyAr" className="block mb-2 text-sm font-medium text-gray-700">Notification Body (AR) <span className='text-red-600 font-extrabold '>*</span></label>
                            <textarea
                                name="notificationBodyAr"
                                id="notificationBodyAr"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.notificationBodyAr}
                                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Notification body (AR)"
                            />
                            {formik.errors.notificationBodyAr && formik.touched.notificationBodyAr && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.notificationBodyAr}</div>
                            )}
                        </div>
                        {/* end body */}


                        {/* select action for hear */}
                        <div className='col-span-2 mx-auto w-[50%]'>
                            <label htmlFor="action" className="block mb-2 text-sm font-medium text-gray-700">
                                Select Action <span className='text-red-600 font-extrabold '>*</span>
                            </label>

                            <select
                                id="action"
                                name="action"
                                value={formik.values.action}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                            >
                                <option value="" disabled>Select Action</option>
                                <option value="topic">Topic</option>
                                <option value="topics">Topics</option>
                                <option value="groupOfUsers">Group of Users</option>
                                <option value="all">All Users</option>
                            </select>

                            {formik.errors.action && formik.touched.action && (
                                <div className="text-red-500 text-sm mt-1">{formik.errors.action}</div>
                            )}
                        </div>
                        {/* select action for hear */}


                        {/* Show Topics input only when action is "topics" or "topic" */}
                        {(formik.values.action === 'topics' || formik.values.action === 'topic') && (
                            <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    {formik.values.action === 'topics' ? 'Select Topics' : 'Select Topic'} <span className="text-red-600 font-extrabold">*</span>
                                </label>

                                {/* Search Input */}
                                <input
                                    type="text"
                                    placeholder="Search topics"
                                    onChange={(e) => setSearchQueryTopics(e.target.value)}
                                    className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mb-3"
                                />

                                {/* Dropdown */}
                                <div className="bg-gray-100 border border-gray-300 rounded-lg p-3 max-h-60 overflow-y-auto">
                                    {filteredTopics?.map((topic, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                type={formik.values.action === 'topics' ? 'checkbox' : 'radio'}
                                                id={`topic-${index}`}
                                                name="topicSelection"
                                                checked={
                                                    formik.values.action === 'topics'
                                                        ? formik.values.topics.includes(topic) // Multiple topics
                                                        : formik.values.topic === topic // Single topic
                                                }
                                                onChange={() => {
                                                    formik.values.action === 'topics'
                                                        ? handleMultipleTopicSelection(topic) // Handle multiple
                                                        : handleSingleTopicSelection(topic); // Handle single
                                                }}
                                                className="w-4 h-4 mr-2"
                                            />
                                            <label htmlFor={`topic-${index}`} className="text-sm text-gray-700">
                                                {topic}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Show Users input only when action is "groupOfUsers"*/}
                        {(formik.values.action === 'groupOfUsers') && (
                            <div className="col-span-2">

                                <label className="block mb-2 text-sm font-medium text-gray-700">
                                    {formik.values.action === 'groupOfUsers' ? 'Select Users' : 'Select Users Token'} <span className="text-red-600 font-extrabold">*</span>
                                </label>

                                {/* Search Input */}
                                <input
                                    type="text"
                                    placeholder="Search Users"
                                    onChange={(e) => setSearchQueryUsers(e.target.value)}
                                    className="bg-gray-100 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 mb-3"
                                />

                                {/* Dropdown */}
                                <div className="bg-gray-100 border border-gray-300 rounded-lg p-3 max-h-60 overflow-y-auto">
                                    {filteredUsers?.map((user, index) => (
                                        <div key={index} className="flex items-center mb-2">
                                            <input
                                                type='checkbox'
                                                id={`user-${index}`}
                                                name="userSelection"
                                                checked={formik.values.groupOfUsers.includes(user?.id)}
                                                onChange={() => { handleMultipleUserSelection(user?.id) }}
                                                className="w-4 h-4 mr-2"
                                            />
                                            <label htmlFor={`user-${index}`} className="text-sm text-gray-700">
                                                {user.fullName}
                                            </label>
                                        </div>
                                    ))}
                                </div>


                                {/* Pagination Controls */}
                                <div className="flex justify-center space-x-2 mt-4">
                                    {/* Previous Button */}
                                    <button
                                        className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>

                                    {/* Page Numbers */}
                                    {[...Array(totalPages)].map((_, i) => (
                                        <button
                                            key={i}
                                            className={`px-4 py-2 ${currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                                                } hover:bg-gray-300 rounded`}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}

                                    {/* Next Button */}
                                    <button
                                        className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="px-5 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300"
                        >
                            Send Notification
                        </button>
                    </div>
                    {error ? <div className="py-2 mt-2 px-2 bg-red-400 text-red-50 rounded-md text-center mx-auto">{error}</div> : ''}
                </form >
            </div >
        </>
    );
}
