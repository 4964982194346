import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from 'react-query';
import axios from 'axios';

export default function BarcharMonth() {
    async function getChartMonth() {
        const response = await axios.get(`${process.env.REACT_APP_BACK_END}admin/dashboard/line-chart-months`);
        return response.data;
    }

    const { data } = useQuery('ChartMonth', getChartMonth, {
        cacheTime: 9000000
    });

    let allChartMonth = [];

    if (data?.message === "Request successful") {
        const months = data.data.coupons.keys;
        const couponValues = data.data.coupons.values;
        const userValues = data.data.users.values;

        const abbreviatedMonths = {
            January: 'Jan', February: 'Feb', March: 'Mar', April: 'Apr',
            May: 'May', June: 'Jun', July: 'Jul', August: 'Aug',
            September: 'Sep', October: 'Oct', November: 'Nov', December: 'Dec'
        };

        allChartMonth = months.map((month, index) => {
            const [monthName, year] = month.split(' ');
            const abbreviatedMonth = abbreviatedMonths[monthName] || monthName;
            return {
                month: `${abbreviatedMonth} ${year}`,  // Abbreviate month
                coupons: couponValues[index],
                users: userValues[index]
            };
        });
    }



    return (
        <div className="container py-1.5 h-full w-full">
            <h1 className='text-center text-teal-500 font-extrabold text-xl xl:text-3xl'>Coupon & User Data in Month</h1>

            <ResponsiveBar
                data={allChartMonth}
                keys={['coupons', 'users']}
                indexBy="month"
                margin={{ top: 50, right: 30, bottom: 80, left: 50 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'set1' }}
                groupMode="grouped"
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: window.innerWidth < 600 ? -45 : -45,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 65,
                    format: value => value  // Keep the formatted month and year from `allChartMonth`
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={window.innerWidth < 600 ? 30 : 12}
                labelSkipHeight={window.innerWidth < 600 ? 30 : 12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                legends={window.innerWidth >= 600 ? [
                    {
                        dataFrom: 'keys',
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ] : []}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => `${e.id}: ${e.formattedValue} in month: ${e.indexValue}`}
            />
        </div>
    );
}
