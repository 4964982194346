import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import logo from '../../Assets/images/couponesta.jpg'
import ForgetPasswordEmail from '../ForgetPasswordEmail/ForgetPasswordEmail.jsx';

export default function ForgetPassword() {

    const location = useLocation();

    return <>

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">


            <div className="sm:mx-auto sm:w-full sm:max-w-sm">

                <Link
                    to={'/'}
                    className='cursor-pointer'
                >
                    <img
                        className="mx-auto h-20 rounded-xl  w-auto"
                        src={logo}
                        alt="Your Company"
                    />
                </Link>

                <h1 className='text-center my-1 md:pt-10 mb-4 md:mb-8 md:text-3xl text-2xl font-bold text-teal-600'>Forget Password</h1>

            </div>

            <ForgetPasswordEmail />

        </div>
    </>
}
